// ./react-app/src/scenes/calendar/index.tsx
import { FC, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import fetchData from "../../helpers/fetchData";
import ButtonLink from "../../components/Buttons";
import moment, { now } from "moment";

interface ICalendarResponse {
  name: string;
  send_date: string | null;
  actual_send_date: string;
  author_name: string | null;
  post_title: string | null;
  post_date: string | null;
  post_permalink: string | null;
  was_sent: boolean;
  campaign_id: string | null;
  nl_id: number | null;
  sam_id: number | null;
  product_id: number | null;
  from_name: string | null;
}

const ColorLegend: React.FC = () => {
  const legends = [
    { label: "SAM", color: "#000000" },
    { label: "Newsletter mit Artikel", color: "#076a00" },
    { label: "Geplanter Newsletter", color: "#3788d8" },
    { label: "Verpasster Newsletter (technischer Fehler)", color: "#df0000" },
    { label: "Verpasster Newsletter (kein Artikel)", color: "#ff7300" },
    { label: "Anderes", color: "#ffeb00" },
  ];

  return (
    <Box display="flex" justifyContent="center" gap={2} mb={2}>
      {legends.map((legend, index) => (
        <Box key={index} display="flex" alignItems="center" gap={1}>
          <Box
            width={15}
            height={15}
            borderRadius="50%"
            bgcolor={legend.color}
          />
          <Typography>{legend.label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const EventDetails: FC<{ event: ICalendarResponse }> = ({ event }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "P h:m", { locale: de });
  };
  return (
    <>
      {moment(event.send_date).isSameOrBefore(now()) ? (
        <Box mb={2}>
          {event.campaign_id ? (
            <Typography variant="h4" color={colors.greenAccent[400]}>
              Kampagne erfolgreich versendet
            </Typography>
          ) : event.post_title !== null ? (
            <Typography variant="h4" color="#df0000">
              Nicht versendet - technischer Fehler
            </Typography>
          ) : (
            <Typography variant="h4" color="#ff7300">
              Nicht versendet - kein Artikel gefunden
            </Typography>
          )}
        </Box>
      ) : (
        ""
      )}
      <TableContainer component={Paper}>
        <Table aria-label="Mailing Event Table" className="calendar-table">
          <TableBody>
            {event.name && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Name
                </TableCell>
                <TableCell>{event.name}</TableCell>
              </TableRow>
            )}
            {event.from_name && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Absender
                </TableCell>
                <TableCell>{event.from_name}</TableCell>
              </TableRow>
            )}
            {event.send_date && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Geplantes Versanddatum
                </TableCell>
                <TableCell>{formatDate(event.send_date)}</TableCell>
              </TableRow>
            )}
            {event.actual_send_date && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Versanddatum
                </TableCell>
                <TableCell>{formatDate(event.actual_send_date)}</TableCell>
              </TableRow>
            )}
            {event.author_name && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Newsletter Autor Name
                </TableCell>
                <TableCell>{event.author_name}</TableCell>
              </TableRow>
            )}
            {event.post_title && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Genutzter Artikel
                </TableCell>
                <TableCell>
                  {event.post_permalink ? (
                    <a
                      href={event.post_permalink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {event.post_title}
                    </a>
                  ) : (
                    event.post_title
                  )}
                </TableCell>
              </TableRow>
            )}
            {event.post_date && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Artikel-Datum
                </TableCell>
                <TableCell>{formatDate(event.post_date)}</TableCell>
              </TableRow>
            )}
            {event.campaign_id && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Mailchimp Kampagne
                </TableCell>
                <TableCell>
                  ID: {event.campaign_id}
                  <ButtonLink
                    text={"Kampagne Ansehen"}
                    float={"right"}
                    link={
                      "https://www.finanztrends.de/adserver/preview/show_mailchimp_campaign_html.php?client=ft&c_id=" +
                      event.campaign_id
                    }
                  />
                </TableCell>
              </TableRow>
            )}
            {event.nl_id !== null && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Newsletter ID
                </TableCell>
                <TableCell>{event.nl_id}</TableCell>
              </TableRow>
            )}
            {event.sam_id !== null && (
              <TableRow>
                <TableCell component="th" scope="row">
                  SAM
                </TableCell>
                <TableCell>
                  ID: {event.sam_id}
                  <ButtonLink
                    text={"SAM Bearbeiten"}
                    float={"right"}
                    link={
                      "https://www.finanztrends.de/adserver/new_material.php?material_type=ad&action=update&id=" +
                      event.sam_id
                    }
                  />
                </TableCell>
              </TableRow>
            )}
            {event.product_id !== null && (
              <TableRow>
                <TableCell component="th" scope="row">
                  Report
                </TableCell>
                <TableCell>
                  ID: {event.product_id}
                  <ButtonLink
                    text={"zum Report"}
                    float={"right"}
                    link={
                      "https://www.finanztrends.de/adserver/product.php?id=" +
                      event.product_id
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const getAndProcessData = async () => {
  const options = {
    start_date: "2023-05-01", //date where "kalender" table was beeing cached again
    end_date: new Date(new Date().setDate(new Date().getDate() + 30))
      .toISOString()
      .split("T")[0],
  };

  const data = (await fetchData<ICalendarResponse>(
    "/api/nl-calendar",
    options
  )) as ICalendarResponse[];
  console.dir(data);
  return data;
};

const Calendar: FC = () => {
  const [events, setEvents] = useState<EventInput[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<ICalendarResponse | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true); // add a new state variable for loading

  const [open, setOpen] = useState(false);

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedEvent(clickInfo.event.extendedProps as ICalendarResponse);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEvent(null);
  };

  useEffect(() => {
    getAndProcessData().then((data) => {
      setEvents(
        data.map(
          (item): EventInput => ({
            title: item.name,
            start: new Date(item.actual_send_date || item.send_date || ""),
            backgroundColor:
              item.sam_id !== null
                ? "#000000"
                : item.post_title !== null && item.campaign_id !== null
                ? "#076a00"
                : (item.actual_send_date || item.send_date) &&
                  new Date(item.actual_send_date || item.send_date || "") >
                    new Date()
                ? "#3788d8"
                : !item.campaign_id
                ? item.post_title !== null
                  ? "#df0000"
                  : "#ff7300"
                : "#ffeb00",
            extendedProps: item,
          })
        )
      );
      setLoading(false); // set loading to false after the data is fetched
    });
  }, []);

  return (
    <Box m="20px">
      <Header
        title="E-Mail Marketing Kalender"
        subtitle="Newsletter Ausgaben & SAMs"
      />
      <ColorLegend />
      {/* CALENDAR */}
      {loading ? (
        <Box
          flex="1 1 100%"
          ml="15px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="75vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridWeek"
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            events={events}
            eventClick={handleEventClick}
            locale={"de"}
            timeZone={"Europe/Berlin"}
            buttonText={{
              today: "Heute",
              month: "Monat",
              week: "Woche",
              day: "Tag",
              list: "Liste",
            }}
          />
        </Box>
      )}
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Versand Details</DialogTitle>
        <DialogContent>
          {selectedEvent && <EventDetails event={selectedEvent} />}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Calendar;
