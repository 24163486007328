// ./react-app/src/components/ListGrowthChart.tsx
import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Box, useTheme } from "@mui/material";
import { useChartTheme } from "../themes/chartTheme";
import fetchData from "../helpers/fetchData";
import { formatGerman } from "../helpers/chartUtils";

interface HistoryItem {
  month: string;
  subscribed: number;
  unsubscribed: number;
  cleaned: number;
  deleted: number;
}

interface RawData {
  history: HistoryItem[];
  list_id: string;
  total_items: number;
  _links: { [key: string]: any }[];
}

const getAndProcessData = async () => {
  const rawData = (await fetchData<RawData>(
    "/api/mailchimp-list-growth",
    {}
  )) as RawData;
  const historyData = rawData.history;

  // Initialize an empty array to hold the processed data
  const processedData: HistoryItem[] = [];

  // Iterate over the historyData array
  historyData.forEach((dataPoint: HistoryItem) => {
    // Create a new data point with the desired structure
    const processedDataPoint: HistoryItem = {
      month: dataPoint.month,
      subscribed: dataPoint.subscribed,
      unsubscribed: dataPoint.unsubscribed,
      cleaned: dataPoint.cleaned,
      deleted: dataPoint.deleted,
    };

    // Add the new data point to the processedData array
    processedData.push(processedDataPoint);
  });

  // Return the processed data
  return { data: processedData.reverse() };
};

const MonthlyListGrowthChart: React.FC = () => {
  const theme = useTheme();

  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    getAndProcessData()
      .then(({ data }) => {
        setData(data);
      })
      .catch((error: Error) => {
        // handle the error
        console.error("Error fetching and processing data: ", error);
      });
  }, []);

  const chartTheme = useChartTheme();
  return (
    <ResponsiveBar
      data={data}
      theme={chartTheme}
      keys={Object.keys(data[0] || {}).filter((key) => key !== "month")}
      indexBy="month"
      margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "set2" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Monat",
        legendPosition: "middle",
        legendOffset: 32,
        format: (d) => {
          return d;
        },
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        format: (d) => {
          return formatGerman.format(d);
        },
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableGridX={false}
      enableGridY={false}
      tooltip={({ id, value, color }) => (
        <Box
          sx={{
            padding: "5px 10px",
            color: "#222",
            background: "#e0e0e0",
            boxShadow: "0 0 2px #222",
          }}
        >
          <strong>{id}</strong>: {formatGerman.format(value)}
        </Box>
      )}
      enableLabel={true}
      label={(bar) => formatGerman.format(bar.value as number)}
      labelFormat={(value) => `${formatGerman.format(value as number)}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      layers={["grid", "axes", "bars", "markers", "legends"]}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default MonthlyListGrowthChart;
