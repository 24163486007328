export const channelNameMapping: Record<string, string> = {
  push: "Push",
  sam: "SAM",
  nl: "Newsletter",
  welcome_campaign: "Mailchimp Willkommens-Kampagne",
  other: "Sonstige",
  "doi-zone": "DOI-Seite",
  website: "Website",
  pdf_analysis: "PDF-Prämie",
};
