//./react-app/src/scenes/leads/exported-leads.tsx
import Header from "../../components/Header";
import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import LpsLeadExportsTable from "../../components/leads/LpsLeadExportsTable";
import UnsubscribedLeadExportsTable from "../../components/leads/UnsubscribedLeadExportsTable";

const ExportedLeadsPage: FC = () => {
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Exportierte Leads nach Kunde/Account"
          subtitle="Start- und Enddatum sowie Lead-Stati können gefiltert werden."
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Lead-Quelle: FIT; Lead-System: LPs
        </Typography>
        <Box mt="-20px">
          <LpsLeadExportsTable />
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Lead-Quelle: FIT-Abmelder; Lead-Exports von Abmeldern an externe
          Kunden
        </Typography>
        <Box mt="-20px">
          <UnsubscribedLeadExportsTable />
        </Box>
      </Box>
    </Box>
  );
};

export default ExportedLeadsPage;
