import { IFilterState } from "../contexts/FilterContext";
import { dateToSqlDate } from "./dateFuncs";
import fetchData, { IResponseData } from "./fetchData";

export async function fetchArticleData<T extends IResponseData>(
  endpoint: string,
  filterState: IFilterState
) {
  if (!filterState.startDate) {
    throw new Error("No start date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: filterState.endDate ? dateToSqlDate(filterState.endDate) : "",
    group_by: filterState.articleGrouping || "author",
    article_type: filterState.articleType || "all",
    view_type: filterState.viewType || "ft",
    view_channel: filterState.viewChannel || "all",
    view_referrer: filterState.viewReferrer || "all",
    exclude_title_start: filterState.custom?.excludeTitleStart || false,
  };

  return await fetchData<T>(endpoint, options);
}
