/* ./react-app/src/components/TitleNounEvaluation.tsx */

// The header mapping for the details table in the popup
import React from "react";
import TableWidget, { HeaderMappingItem } from "../../helpers/tableBuilder";
import { IFilterState } from "../../contexts/FilterContext";
import { fetchArticleData } from "../../helpers/articleData";

const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  has_number: {
    name: "Zahl im Titel",
    type: "string",
    digits: 0,
    attach: "",
  },
  num_posts: {
    name: "Anzahl Artikel",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_views_ft: {
    name: "Avg Views (FT)",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_views_ga: {
    name: "Avg Views (GA)",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_leads: {
    name: "Avg Leads",
    type: "number",
    digits: 2,
    attach: "",
  },
};

type ApiResponse = {
  has_number: number;
  post_count: number;
  ft_views: number;
  ga_views: number;
  leads: number;
};

type MappedData = {
  has_number: number;
  num_posts: number;
  avg_views_ft: number;
  avg_views_ga: number;
  avg_leads: number;
  sum_views_ft: number;
  sum_views_ga: number;
  sum_leads: number;
};

const getData: (filterState: IFilterState) => Promise<MappedData[]> = async (
  filterState: IFilterState
) => {
  const data = await fetchArticleData(
    "/api/articles/evaluation/title-has-number",
    filterState
  );
  console.dir("unprocessed");
  console.dir(data);
  return data.map((item: ApiResponse) => {
    return {
      has_number: item.has_number,
      num_posts: item.post_count,
      sum_views_ft: item.ft_views,
      sum_views_ga: item.ga_views,
      sum_leads: item.leads,
      avg_views_ft: item.ft_views / item.post_count,
      avg_views_ga: item.ga_views / item.post_count,
      avg_leads: item.leads / item.post_count,
    };
  });
};

const computeSummary = (data: MappedData[]): MappedData => {
  const summaryData: MappedData = {
    has_number: 0,
    num_posts: 0,
    avg_views_ft: 0,
    avg_views_ga: 0,
    avg_leads: 0,
    sum_views_ft: 0,
    sum_views_ga: 0,
    sum_leads: 0,
  };
  data.forEach((item) => {
    summaryData.num_posts += item.num_posts;
    summaryData.sum_views_ft += item.sum_views_ft;
    summaryData.sum_views_ga += item.sum_views_ga;
    summaryData.sum_leads += item.sum_leads;
  });
  summaryData.avg_views_ft = summaryData.sum_views_ft / summaryData.num_posts;
  summaryData.avg_views_ga = summaryData.sum_views_ga / summaryData.num_posts;
  summaryData.avg_leads = summaryData.sum_leads / summaryData.num_posts;
  return summaryData;
};

const TitleHasNumberEvaluation: React.FC = () => {
  return (
    <>
      <TableWidget<MappedData>
        getData={(filterState) => getData(filterState)}
        computeSummary={computeSummary}
        detailsHeaderMapping={detailsHeaderMapping}
        initialSortColumn="num_posts"
        excelDownloadName="title-has-number-evaluation"
        sortOrder="desc"
      />
    </>
  );
};

export default TitleHasNumberEvaluation;
