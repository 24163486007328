import React from "react";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import { ButtonProps } from "@mui/material";
import Link from "@mui/material/Link";

const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  borderRadius: 3,
  border: 0,
  color: "white",
  height: 48,
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  "& .MuiButton-label": {
    textTransform: "capitalize",
  },
}));

interface ButtonLinkProps {
  text: string;
  link: string;
  target?: string;
  float?: string | null;
}

export default function ButtonLink({
  text,
  link,
  target = "_blank",
  float = null,
}: ButtonLinkProps) {
  return (
    <Link
      href={link}
      target={target}
      rel="noopener noreferrer"
      underline="none"
      sx={float ? { float: float } : {}}
    >
      <Button variant="outlined" sx={float ? { float: float } : {}}>
        {text}
      </Button>
    </Link>
  );
}
