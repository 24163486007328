//./react-app/src/scenes/traffic-leads/index.tsx
import Header from "../../components/Header";
import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import LpsLeadsChart from "../../components/TrafficLeadsBarChart";

const TrafficLeads: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header title="Traffic-Leads" subtitle="Lead-Gen Entwicklung" />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            LPs Leads
          </Typography>
          <Box height="250px" mt="-20px">
            <LpsLeadsChart />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TrafficLeads;
