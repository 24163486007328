export function formatNumber(
  value: number,
  decimals: number,
  locale = "de-DE"
): string {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  return formatter.format(value);
}
