// DailyControllingEmailGenerator.ts

import fetchData from "../helpers/fetchData";
import { formatDataValue } from "../helpers/formatFuncs";
import { dateToSqlDate, getTimespans } from "../helpers/dateFuncs";
import {
  sources,
  timespanLabels,
} from "../components/leads/DailyControllingTable";

interface Campaign {
  name: string;
  quota: number;
  quota_used: number;
}

interface ClientData {
  client_id: number;
  client_name: string;
  lead_price: number;
}

interface LeadData {
  leads: number;
  turnover: number;
}

interface AggregatedData {
  [source: string]: {
    timespans: {
      [timespan: string]: {
        [client_id: number]: LeadData;
      };
    };
    available_clients: number[];
  };
}

interface SoldLeadsData {
  [timespan: string]: {
    [client_id: number]: LeadData;
  };
}

class DailyControllingEmailGenerator {
  public CUSTOMERS: Record<string, string> = {
    ft: "Finanztrends",
    bg: "Börse Global",
  };
  public DB_LINKS: Record<string, string> = {
    ft: "https://db.finanztrends.de/daily-controlling",
    bg: "https://db.finanztrends.de/boerse-global/daily-controlling",
  };

  private readonly customer: string;
  private customerName: string;
  private filterState: {
    startDate: Date;
    endDate: Date;
    leadStatus: string;
  };

  constructor(
    customer: string,
    startDate: Date,
    endDate: Date,
    leadStatus: string
  ) {
    if (!Object.keys(this.CUSTOMERS).includes(customer)) {
      throw new Error(`Unknown customer: ${customer}`);
    }
    this.customer = customer;
    this.customerName = this.CUSTOMERS[customer];
    this.filterState = {
      startDate,
      endDate,
      leadStatus,
    };
  }

  public async generateEmail(): Promise<string> {
    const campaigns = await this.getCampaigns();
    const soldLeads = await this.getSoldLeads(this.customer);
    const clientData = await this.getClients(this.customer);
    let allLeadsData = await this.getData(this.customer);
    const dbLink = this.DB_LINKS[this.customer];

    // Compute Average and Forecast for AggregatedData
    this.computeAverageAndForecast(allLeadsData, clientData);

    // Compute Average and Forecast for SoldLeadsData
    this.computeAverageAndForecastSoldLeads(soldLeads, clientData);

    return `<html lang="de-DE">
        <head>
          <meta charset="UTF-8">
          <title>Daily Controlling Report</title>
          ${this.generateTableStyles()}
        </head>
        <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
          <table border="0" style="border-style:none;">
            <tr height="0" border="0" style="border-style:none;">
              <td height="0" border="0" style="border-style:none;"></td>
              <td border="0" style="border-style:none;"></td>
              <td border="0" style="border-style:none;"></td>
            </tr>
            <tr border="0" style="border-style:none;">
              <td width="10" border="0" style="border-style:none;"></td>
              <td border="0" style="border-style:none;">
                <h1 style="font-size: 24px; color: #444;">Daily Controlling ${
                  this.customerName
                }</h1>
                <p>Lead-Gen Auswertung: Neue Leads</p>
                <p style="margin-bottom: 20px;">Mehr Details sowie Auswertungen zu anderen Lead-Status (Dubletten, Fehler, etc.) <a href="${dbLink}">hier</a></p>
                ${
                  this.customer === "ft"
                    ? this.generateCampaignsTable(campaigns)
                    : ""
                }
                ${this.generateSoldLeadsTable(soldLeads, clientData)}
                ${this.generateDailyControllingTable(
                  allLeadsData,
                  clientData,
                  this.customer
                )}
              </td>
              <td width="10" border="0" style="border-style:none;"></td>
            </tr>
            <tr height="10" border="0" style="border-style:none;">
              <td height="10" border="0" style="border-style:none;"></td>
              <td border="0" style="border-style:none;"></td>
              <td border="0" style="border-style:none;"></td>
            </tr>
          </table>
        </body>
      </html>
    `;
  }

  private getSourceMap(customer: string): { [source: string]: string } {
    const customerSources = sources[customer];
    return customerSources.reduce((acc, source) => {
      const key = Object.keys(source)[0];
      acc[key] = source[key];
      return acc;
    }, {} as { [source: string]: string });
  }

  private async getClients(
    customer: string
  ): Promise<Record<number, { client_name: string; lead_price: number }>> {
    const clientData = (await fetchData<ClientData[]>(
      `/api/daily-controlling/lead-clients/${customer}`,
      {}
    )) as ClientData[];
    const clientDataMap: Record<
      number,
      { client_name: string; lead_price: number }
    > = {
      0: { client_name: "Insgesamt", lead_price: 0 },
    };
    clientData.forEach((client) => {
      clientDataMap[client.client_id] = {
        client_name: client.client_name,
        lead_price: client.lead_price,
      };
    });
    return clientDataMap;
  }

  private async getCampaigns(): Promise<Campaign[]> {
    return (await fetchData<Campaign[]>(
      "/api/daily-controlling/campaigns/" + this.customer,
      {}
    )) as Campaign[];
  }

  private async getData(customer: string): Promise<AggregatedData> {
    const clientData = await this.getClients(customer);
    const options = {
      start_date: dateToSqlDate(this.filterState.startDate),
      end_date: dateToSqlDate(this.filterState.endDate),
      lead_status: this.filterState.leadStatus,
    };
    const data = (await fetchData<
      { source: string; date: string; client_id: number; count: number }[]
    >(`/api/daily-controlling/data/${customer}`, options)) as {
      source: string;
      date: string;
      client_id: number;
      count: number;
    }[];
    const timespans = getTimespans();
    const aggregatedData: AggregatedData = {};

    sources[customer].forEach((source) => {
      const sourceKey = Object.keys(source)[0];
      aggregatedData[sourceKey] = {
        timespans: {},
        available_clients: [0],
      };
      timespans.forEach((timespan) => {
        aggregatedData[sourceKey].timespans[timespan.label] = {
          0: { leads: 0, turnover: 0 },
        };
        data.forEach((row) => {
          if (row.source === sourceKey && timespan.check(new Date(row.date))) {
            if (
              !aggregatedData[sourceKey].timespans[timespan.label][
                row.client_id
              ]
            ) {
              aggregatedData[sourceKey].timespans[timespan.label][
                row.client_id
              ] = {
                leads: 0,
                turnover: 0,
              };
            }
            const clientLeadPrice =
              this.filterState.leadStatus === "new"
                ? clientData[row.client_id]?.lead_price ?? 0
                : 0;
            aggregatedData[sourceKey].timespans[timespan.label][
              row.client_id
            ].leads += row.count;
            aggregatedData[sourceKey].timespans[timespan.label][
              row.client_id
            ].turnover += row.count * clientLeadPrice;
            aggregatedData[sourceKey].timespans[timespan.label][0].leads +=
              row.count;
            aggregatedData[sourceKey].timespans[timespan.label][0].turnover +=
              row.count * clientLeadPrice;
            if (
              !aggregatedData[sourceKey].available_clients.includes(
                row.client_id
              )
            ) {
              aggregatedData[sourceKey].available_clients.push(row.client_id);
            }
          }
        });
      });
    });
    return aggregatedData;
  }

  private async getSoldLeads(customer: string): Promise<SoldLeadsData> {
    const data = await this.getData(customer);
    const clientData = await this.getClients(customer);
    const timespans = getTimespans();
    let soldLeads: SoldLeadsData = {};

    timespans.forEach((timespan) => {
      soldLeads[timespan.label] = {};
      const allSourceData = data["all"].timespans[timespan.label];
      Object.keys(allSourceData).forEach((clientIdStr) => {
        const clientId = parseInt(clientIdStr, 10);
        const client = clientData[clientId];
        if (client && client.lead_price > 0) {
          soldLeads[timespan.label][clientId] = allSourceData[clientId];
        }
      });
      soldLeads[timespan.label][0] = Object.values(
        soldLeads[timespan.label]
      ).reduce(
        (acc, curr) => ({
          leads: acc.leads + curr.leads,
          turnover: acc.turnover + curr.turnover,
        }),
        { leads: 0, turnover: 0 }
      );
    });
    return soldLeads;
  }

  private generateTableStyles(): string {
    return `
      <style>
        table {
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 20px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
          font-weight: bold;
        }
        .subtable {
          margin: 0;
        }
        .subtable th, .subtable td {
          border: none;
          padding: 4px 8px;
        }
        .subtable th {
          background-color: #e5e7eb;
        }
        .total-row {
          background-color: #f2f2f2;
          font-weight: bold;
        }
      </style>
    `;
  }

  private generateCampaignsTable(campaigns: Campaign[]): string {
    let html =
      '<h2 style="font-size: 18px; font-weight: bold; margin-top: 20px; margin-bottom: 10px;">Live Kampagnen</h2>';
    html +=
      '<table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">';
    html +=
      '<tr><th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">Kampagne</th><th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">Kontingent</th><th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">Leads</th></tr>';

    campaigns.forEach((campaign) => {
      html += `
        <tr>
          <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">${
            campaign.name
          }</td>
          <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">${formatDataValue(
            campaign.quota,
            "number",
            0
          )}</td>
          <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">${formatDataValue(
            campaign.quota_used,
            "number",
            0
          )}</td>
        </tr>`;
    });

    html += "</table>";
    return html;
  }

  private generateSoldLeadsTable(
    soldLeads: SoldLeadsData,
    clientData: Record<number, { client_name: string; lead_price: number }>
  ): string {
    let html =
      '<h2 style="font-size: 18px; font-weight: bold; margin-top: 20px; margin-bottom: 10px;">Verkaufte Leads</h2>';
    html +=
      '<table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">';
    html +=
      '<tr><th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">Kunde</th>';

    // Include "Average" and "Forecast" in the headers
    timespanLabels.forEach((timespanLabel) => {
      html += `<th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">${timespanLabel}</th>`;
    });

    html += "</tr>";

    const allClientIds = Array.from(
      new Set(
        timespanLabels.flatMap((timespanLabel) =>
          Object.keys(soldLeads[timespanLabel] || {}).map(Number)
        )
      )
    ).sort((a, b) => a - b);

    allClientIds.forEach((clientId) => {
      const isTotal = clientId === 0;
      html += `<tr style="${isTotal ? "background-color: #e5e7eb;" : ""}">`;
      html += `<td style="${
        isTotal ? "font-weight:bold;" : ""
      } border: 1px solid #ddd; padding: 8px; text-align: left;">${
        clientData[clientId]?.client_name ?? "Unbekannt"
      }</td>`;

      timespanLabels.forEach((timespanLabel) => {
        const data = soldLeads[timespanLabel]?.[clientId] || {
          leads: 0,
          turnover: 0,
        };
        html += `
          <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">
            <strong>${data.leads}</strong>${
          data.leads
            ? " (" + formatDataValue(data.turnover, "number", 0, "€") + ")"
            : ""
        }
          </td>`;
      });

      html += "</tr>";
    });

    html += "</table>";
    return html;
  }

  private generateDailyControllingTable(
    data: AggregatedData,
    clientData: Record<number, { client_name: string; lead_price: number }>,
    customer: string
  ): string {
    const sourceMap = this.getSourceMap(customer);
    let html = `
      <h2 style="font-size: 18px; font-weight: bold; margin-top: 20px; margin-bottom: 10px;">Alle Leads</h2>
      <table style="border-collapse: collapse; width: 100%; margin-bottom: 20px;">
        <tr>
          <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">Quelle</th>
    `;

    // Add headers for all timespans including Average and Forecast
    timespanLabels.forEach((timespan) => {
      html += `<th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">${timespan}</th>`;
    });

    html += `</tr>`;
    let i = 0;
    Object.keys(data).forEach((source) => {
      if (i !== 0) {
        html += `<tr><td style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">&nbsp;</td>`;
        timespanLabels.forEach((timespan) => {
          html += `<th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2; font-weight: bold;">${timespan}</th>`;
        });
        html += `</tr>`;
      }
      i++;
      html += `
        <tr>
          <td style="border: 1px solid #ddd; padding: 8px; text-align: left; font-weight: bold; background-color: #e5e7eb;">
            ${sourceMap[source] ?? source}
          </td>
      `;

      timespanLabels.forEach((timespan) => {
        const totalData = data[source].timespans[timespan]?.[0] || {
          leads: 0,
          turnover: 0,
        };
        html += `
          <td style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #e5e7eb;">
            <strong>${totalData.leads}</strong>${
          totalData.leads
            ? " (" + formatDataValue(totalData.turnover, "number", 0, "€") + ")"
            : ""
        }
          </td>
        `;
      });

      html += `</tr>`;

      data[source].available_clients.forEach((clientId) => {
        if (clientId !== 0) {
          html += `
            <tr>
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">
                ${clientData[clientId]?.client_name ?? "Unknown Client"}
              </td>
          `;

          timespanLabels.forEach((timespan) => {
            const clientDataEntry = data[source].timespans[timespan]?.[
              clientId
            ] || { leads: 0, turnover: 0 };
            html += `
              <td style="border: 1px solid #ddd; padding: 8px; text-align: left;">
                <strong>${clientDataEntry.leads}</strong>${
              clientDataEntry.leads
                ? " (" +
                  formatDataValue(clientDataEntry.turnover, "number", 0, "€") +
                  ")"
                : ""
            }
              </td>
            `;
          });

          html += `</tr>`;
        }
      });
    });

    html += `</table>`;
    return html;
  }

  /**
   * Computes the Average and Forecast timespans and augments the AggregatedData.
   * @param aggregatedData The original aggregated data fetched from the API.
   * @param clientData The client data mapping.
   */
  private computeAverageAndForecast(
    aggregatedData: AggregatedData,
    clientData: Record<number, { client_name: string; lead_price: number }>
  ) {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const daysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();

    Object.keys(aggregatedData).forEach((source) => {
      const sourceData = aggregatedData[source];

      // Initialize Average and Forecast timespans
      sourceData.timespans["Average"] = {};
      sourceData.timespans["Forecast"] = {};

      sourceData.available_clients.forEach((client_id) => {
        let relevantData: LeadData;
        let relevantDays: number;

        if (dayOfMonth >= 5) {
          // Use "Dieser Monat" data
          relevantData = sourceData.timespans["Dieser Monat"][client_id] || {
            leads: 0,
            turnover: 0,
          };
          relevantDays = dayOfMonth;
        } else {
          // Use "temp_last_5_days" data
          relevantData = sourceData.timespans["temp_last_5_days"]?.[
            client_id
          ] || { leads: 0, turnover: 0 };
          relevantDays = 5;
        }

        const avgLeadsPerDay =
          relevantDays > 0 ? relevantData.leads / relevantDays : 0;
        const avgTurnoverPerDay =
          relevantDays > 0 ? relevantData.turnover / relevantDays : 0;

        // Forecast
        const forecastLeads = avgLeadsPerDay * daysInMonth;
        const forecastTurnover = avgTurnoverPerDay * daysInMonth;

        // Assign to Average and Forecast timespans
        sourceData.timespans["Average"][client_id] = {
          leads: Math.round(avgLeadsPerDay),
          turnover: Math.round(avgTurnoverPerDay),
        };
        sourceData.timespans["Forecast"][client_id] = {
          leads: Math.round(forecastLeads),
          turnover: Math.round(forecastTurnover),
        };
      });
    });
  }

  /**
   * Computes the Average and Forecast timespans for SoldLeadsData and augments it.
   * @param soldLeads The original sold leads data fetched from the API.
   * @param clientData The client data mapping.
   */
  private computeAverageAndForecastSoldLeads(
    soldLeads: SoldLeadsData,
    clientData: Record<number, { client_name: string; lead_price: number }>
  ) {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const daysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();

    // Determine relevant timespan
    let relevantTimespanLabel: string;
    let relevantDays: number;

    if (dayOfMonth >= 5) {
      relevantTimespanLabel = "Dieser Monat";
      relevantDays = dayOfMonth;
    } else {
      relevantTimespanLabel = "temp_last_5_days";
      relevantDays = 5;
    }

    // Check if the relevant timespan exists in soldLeads
    if (!soldLeads[relevantTimespanLabel]) {
      // Initialize it with zero values if it doesn't exist
      soldLeads[relevantTimespanLabel] = {};
      Object.keys(clientData).forEach((clientIdStr) => {
        const clientId = parseInt(clientIdStr, 10);
        if (clientId !== 0) {
          soldLeads[relevantTimespanLabel][clientId] = {
            leads: 0,
            turnover: 0,
          };
        }
      });
      soldLeads[relevantTimespanLabel][0] = {
        leads: 0,
        turnover: 0,
      };
    }

    // Initialize Average and Forecast
    soldLeads["Average"] = {};
    soldLeads["Forecast"] = {};

    // Iterate through clients in the relevant timespan
    const relevantSoldLeadsData = soldLeads[relevantTimespanLabel];
    Object.keys(relevantSoldLeadsData).forEach((clientIdStr) => {
      const clientId = parseInt(clientIdStr, 10);
      if (clientId === 0) return; // Skip total

      const data = soldLeads[relevantTimespanLabel][clientId] || {
        leads: 0,
        turnover: 0,
      };

      const avgLeadsPerDay = relevantDays > 0 ? data.leads / relevantDays : 0;
      const avgTurnoverPerDay =
        relevantDays > 0 ? data.turnover / relevantDays : 0;

      // Forecast
      const forecastLeads = avgLeadsPerDay * daysInMonth;
      const forecastTurnover = avgTurnoverPerDay * daysInMonth;

      // Assign to Average and Forecast timespans
      soldLeads["Average"][clientId] = {
        leads: Math.round(avgLeadsPerDay),
        turnover: Math.round(avgTurnoverPerDay),
      };
      soldLeads["Forecast"][clientId] = {
        leads: Math.round(forecastLeads),
        turnover: Math.round(forecastTurnover),
      };
    });

    // Calculate totals for Average and Forecast
    ["Average", "Forecast"].forEach((label) => {
      soldLeads[label][0] = Object.values(soldLeads[label]).reduce(
        (acc, curr) => ({
          leads: acc.leads + curr.leads,
          turnover: acc.turnover + curr.turnover,
        }),
        { leads: 0, turnover: 0 }
      );
    });
  }
}

export default DailyControllingEmailGenerator;
