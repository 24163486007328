/* ./react-app/src/components/TitleLengthEvaluation.tsx */

// The header mapping for the details table in the popup
import React, { useContext } from "react";
import TableWidget, { HeaderMappingItem } from "../../helpers/tableBuilder";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import { fetchArticleData } from "../../helpers/articleData";
import { Checkbox, FormControlLabel } from "@mui/material";

const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  title_words: {
    name: "Anzahl Wörter",
    type: "number",
    digits: 0,
    attach: "",
  },
  num_posts: {
    name: "Anzahl Artikel",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_views_ft: {
    name: "Avg Views (FT)",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_views_ga: {
    name: "Avg Views (GA)",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_leads: {
    name: "Avg Leads",
    type: "number",
    digits: 2,
    attach: "",
  },
};

type ApiResponse = {
  title_words: number;
  post_count: number;
  ft_views: number;
  ga_views: number;
  leads: number;
};

type MappedData = {
  title_words: number;
  num_posts: number;
  avg_views_ft: number;
  avg_views_ga: number;
  avg_leads: number;
  sum_views_ft: number;
  sum_views_ga: number;
  sum_leads: number;
};

const getData: (filterState: IFilterState) => Promise<MappedData[]> = async (
  filterState: IFilterState
) => {
  const data = await fetchArticleData(
    "/api/articles/evaluation/title-length",
    filterState
  );
  //calculate the average values
  return data.map((item: ApiResponse) => {
    return {
      title_words: item.title_words,
      num_posts: item.post_count,
      sum_views_ft: item.ft_views,
      sum_views_ga: item.ga_views,
      sum_leads: item.leads,
      avg_views_ft: item.ft_views / item.post_count,
      avg_views_ga: item.ga_views / item.post_count,
      avg_leads: item.leads / item.post_count,
    };
  });
};

const computeSummary = (data: MappedData[]): MappedData => {
  const summaryData: MappedData = {
    title_words: 0,
    num_posts: 0,
    avg_views_ft: 0,
    avg_views_ga: 0,
    avg_leads: 0,
    sum_views_ft: 0,
    sum_views_ga: 0,
    sum_leads: 0,
  };
  data.forEach((item) => {
    summaryData.title_words += item.title_words;
    summaryData.num_posts += item.num_posts;
    summaryData.sum_views_ft += item.sum_views_ft;
    summaryData.sum_views_ga += item.sum_views_ga;
    summaryData.sum_leads += item.sum_leads;
  });
  summaryData.avg_views_ft = summaryData.sum_views_ft / summaryData.num_posts;
  summaryData.avg_views_ga = summaryData.sum_views_ga / summaryData.num_posts;
  summaryData.avg_leads = summaryData.sum_leads / summaryData.num_posts;
  return summaryData;
};

const TitleLengthEvaluation: React.FC = () => {
  const { filterState, setFilterState } = useContext(FilterContext)!;

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterState((prevState) => ({
      ...prevState,
      custom: {
        ...prevState.custom,
        excludeTitleStart: event.target.checked,
      },
    }));
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={filterState.custom?.excludeTitleStart || false}
            onChange={handleCheckboxChange}
            name="excludeStart"
            color="primary"
          />
        }
        label="Titel-Anfang filtern (Aktienname + ' Aktie:')"
      />
      <TableWidget<MappedData>
        getData={(filterState) => getData(filterState)}
        computeSummary={computeSummary}
        detailsHeaderMapping={detailsHeaderMapping}
        initialSortColumn="title_words"
        excelDownloadName="title-length-evaluation"
      />
    </>
  );
};

export default TitleLengthEvaluation;
