// ./react-app/src/components/articles/ArticleCustomGrouping.tsx
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { visuallyHidden } from "@mui/utils";
import fetchData from "../../helpers/fetchData";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import LoadingSpinner from "./../LoadingSpinner";
import { formatNumber } from "../../helpers/numberUtils";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../../helpers/sortingsFuncs";
import { tokens } from "../../theme";
import DetailsModal, {
  DetailsTableProps,
  HeaderMappingItem,
} from "./ArticleDetails";
import { exportToExcel } from "../../helpers/exportFuncs";

type IArticleStatisticsResponse = Record<string, string>;
type DataKey = keyof IArticleStatisticsResponse;
type Data = Record<DataKey, string>;

// The header mapping
const headerMapping: Record<string, HeaderMappingItem> = {
  word_count_group: {
    name: "Wortanzahl",
    type: "number",
    digits: 0,
    attach: "",
  },
  author_name: {
    name: "Autor",
    type: "string",
    digits: 0,
    attach: "",
  },
  asset_name: {
    name: "Aktie/Asset",
    type: "string",
    digits: 0,
    attach: "",
  },
  total_posts: {
    name: "Artikel insg.",
    type: "number",
    digits: 0,
    attach: "",
  },
  sum_views: {
    name: "Views insg.",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_views: {
    name: "Ø Views",
    type: "number",
    digits: 0,
    attach: "",
  },
  view_channel_share: {
    name: "% aller Views",
    type: "percentage",
    digits: 0,
    attach: "%",
  },
  sum_leads: {
    name: "Leads insg.",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_leads: {
    name: "Ø Leads",
    type: "number",
    digits: 2,
    attach: "",
  },
  sum_turnover: {
    name: "Umsatz insg.",
    type: "number",
    digits: 0,
    attach: "€",
  },
  avg_turnover: {
    name: "Ø Umsatz",
    type: "number",
    digits: 2,
    attach: "€",
  },
  avg_session_duration: {
    name: "Ø Session-Dauer",
    type: "number",
    digits: 0,
    attach: "s",
  },
  posts_below_250: {
    name: "< 250 Views",
    type: "number",
    digits: 0,
    attach: "",
    showRelative: true,
  },
  posts_250_to_1500: {
    name: "250 - 1500 Views",
    type: "number",
    digits: 0,
    attach: "",
    showRelative: true,
  },
  posts_above_1500: {
    name: "> 1500 Views",
    type: "number",
    digits: 0,
    attach: "",
    showRelative: true,
  },
};

const getAndProcessData = async (filterState: IFilterState) => {
  if (!filterState.startDate) {
    throw new Error("No start date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: filterState.endDate ? dateToSqlDate(filterState.endDate) : "",
    group_by: filterState.articleGrouping || "author",
    article_type: filterState.articleType || "all",
    view_type: filterState.viewType || "ft",
    view_channel: filterState.viewChannel || "all",
    view_referrer: filterState.viewReferrer || "all",
  };

  const rawData = await fetchData<IArticleStatisticsResponse>(
    "/api/articles/custom-view-grouping",
    options
  );

  let dataArray: IArticleStatisticsResponse[] = [];

  if (Array.isArray(rawData)) {
    dataArray = rawData;
  } else {
    dataArray.push(rawData);
  }
  //use filterState.leadValue to add sum_turnover and avg_turnover to data array if sum_leads > 0
  if (filterState.leadValue > 0) {
    dataArray = dataArray.map((item) => {
      if (Number(item.total_posts) > 0) {
        item.sum_turnover = String(
          Number(item.sum_leads) * Number(filterState.leadValue)
        );
        item.avg_turnover = String(
          Number(item.sum_turnover) / Number(item.total_posts)
        );
      }
      return item;
    });
  }
  return dataArray;
};

export const GroupedArticles: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<DataKey>("avg_views");
  const [data, setData] = useState<IArticleStatisticsResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<DetailsTableProps>({
    startDate: filterState.startDate as Date,
    endDate: filterState.endDate as Date,
    articleType: filterState.articleType,
    leadValue: filterState.leadValue,
    authorId: 0,
    assetId: 0,
  });

  const handleOpenModal = (options: typeof modalOptions) => {
    setModalOptions(options);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setSearchTerm("");
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getAndProcessData(filterState);
        setData(Array.isArray(data) ? data : [data]);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const tempHeaderMapping = { ...headerMapping };

  if (filterState.articleGrouping === "author") {
    delete tempHeaderMapping.asset_name;
    delete tempHeaderMapping.word_count_group;
  } else if (filterState.articleGrouping === "asset") {
    delete tempHeaderMapping.author_name;
    delete tempHeaderMapping.word_count_group;
  } else if (filterState.articleGrouping === "word_count") {
    delete tempHeaderMapping.author_name;
    delete tempHeaderMapping.asset_name;
  }
  if (filterState.viewChannel === "all" && filterState.viewReferrer === "all") {
    delete tempHeaderMapping.view_channel_share;
  }

  const headers = Object.keys(tempHeaderMapping);

  const computeSummary = (data: IArticleStatisticsResponse[]) => {
    const summary: Partial<IArticleStatisticsResponse> = {};

    for (const key of headers) {
      const details = tempHeaderMapping[key];
      if (details.type === "number") {
        summary[key] = String(
          data.reduce((acc, curr) => acc + Number(curr[key]), 0)
        );
      } else {
        summary[key] = ""; // Not applicable for strings in this context
      }
    }

    // Now, correct the average fields
    if (summary.avg_views && summary.sum_views && summary.total_posts) {
      summary.avg_views = String(
        Number(summary.sum_views) / Number(summary.total_posts)
      );
    }

    if (
      summary.view_channel_share &&
      summary.total_views &&
      summary.sum_views
    ) {
      summary.view_channel_share = String(
        Number(summary.sum_views) / Number(summary.total_views)
      );
    }

    if (summary.avg_leads && summary.sum_leads && summary.total_posts) {
      summary.avg_leads = String(
        Number(summary.sum_leads) / Number(summary.total_posts)
      );
    }

    if (summary.avg_turnover && summary.sum_turnover && summary.total_posts) {
      summary.avg_turnover = String(
        Number(summary.sum_turnover) / Number(summary.total_posts)
      );
    }

    if (summary.avg_session_duration) {
      //divide sum of avg session durations per grouping key ( e.g. author/asset) by number of grouping keys
      summary.avg_session_duration = String(
        Number(summary.avg_session_duration) / Number(data.length)
      );
    }

    return summary;
  };

  const filteredData = data.filter((row) => {
    return (
      row.author_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.asset_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const summaryData = computeSummary(filteredData);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box bgcolor="#fff" pb="30px">
        <Typography variant="h5" color={colors.blueAccent[400]}>
          <p>
            Derzeit kann die Auswertung &uuml;ber Autoren, Aktien und Wortanzahl
            (in 100er Schritten) gruppiert werden.
          </p>
          <p>
            Es ist zu empfehlen das End-Datum mindestens 15 Tage in die
            Vergangenheit zu setzen, da die Daten sonst nicht
            aussagekr&auml;ftig sind. ( Aufgrund von nachlaufendem Traffic )
          </p>
          <p>
            Von vornherein rausgefiltert sind alle SEO-Artikel ( im Post-Editor
            Haken bei &quot;aktualisierte Artikel&quot; gesetzt ).
          </p>
          <p>
            Der Umsatz ist als kalkulatorischer Umsatz angegeben und errechnet
            sich aus dem Lead-Wert (€) * Anzahl der Leads.
            <br /> Als Standardwert wird dabei mit 8€ gerechnet ( Wert den ein
            Lead innerhalb von 12 Monaten durch ENL-Umsätze im Finanztrends
            Stamm mindestens generiert ).
          </p>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" bgcolor="#fff" px={3} py={2}>
        <Box flexGrow={1} width="70%">
          <TextField
            fullWidth
            label="Suche"
            variant="outlined"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Box>
        <Box width="30%" ml={2}>
          <Button
            onClick={() =>
              exportToExcel(filteredData, "Dashboard-Artikel-Auswertung")
            }
            sx={{ float: "right" }}
          >
            Download als Excel
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Gruppierte Artikelauswertung">
          <TableHead>
            <TableRow>
              <TableCell>Artikel anzeigen</TableCell>
              {headers.map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={createSortHandler(header)}
                  >
                    {tempHeaderMapping[header].name || header}
                    {orderBy === header ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<Data>(
              filteredData,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                data-groupid={row.author_id || row.asset_id}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {filterState.articleGrouping !== "word_count" && (
                  <TableCell>
                    <ExpandMoreIcon
                      onClick={() =>
                        handleOpenModal({
                          startDate: filterState.startDate as Date,
                          endDate: filterState.endDate as Date,
                          articleType: filterState.articleType,
                          leadValue: filterState.leadValue,
                          authorId:
                            filterState.articleGrouping === "author"
                              ? Number(row.author_id)
                              : undefined,
                          assetId:
                            filterState.articleGrouping === "asset"
                              ? Number(row.asset_id)
                              : undefined,
                        })
                      }
                    />
                  </TableCell>
                )}
                {Object.entries(tempHeaderMapping).map(([key, value]) => (
                  <TableCell key={key}>
                    {value.type === "number" || value.type === "percentage"
                      ? formatNumber(
                          Number(row[key]) *
                            (value.type === "percentage" ? 100 : 1),
                          value.digits
                        ) +
                        (value.attach || "") +
                        (value.showRelative
                          ? " ( " +
                            formatNumber(
                              (Number(row[key]) / Number(row.total_posts)) *
                                100,
                              0
                            ) +
                            "% )"
                          : "")
                      : row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell key="emptyfootercell"></TableCell>
              {headers.map((header) => (
                <TableCell
                  key={header}
                  sx={{ fontWeight: "bold", fontSize: ".8rem" }}
                >
                  {tempHeaderMapping[header].type === "number"
                    ? formatNumber(
                        Number(summaryData[header]),
                        tempHeaderMapping[header].digits
                      ) +
                      (headerMapping[header].attach || "") +
                      (tempHeaderMapping[header].showRelative
                        ? " ( " +
                          formatNumber(
                            (Number(summaryData[header]) /
                              Number(summaryData.total_posts || "1")) *
                              100,
                            0
                          ) +
                          "% )"
                        : "")
                    : summaryData[header]}
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {modalOpen && (
        <DetailsModal
          open={modalOpen}
          onClose={handleCloseModal}
          {...modalOptions}
        />
      )}
    </Box>
  );
};
