import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingSpinner: React.FC = () => (
  <Box
    flex="1 1 100%"
    ml="15px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="75vh"
  >
    <CircularProgress />
  </Box>
);

export default LoadingSpinner;
