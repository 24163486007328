export const dateToSqlDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const sqlDateToDate = (sqlDate: string): Date => {
  const [year, month, day] = sqlDate.split("-");
  return new Date(Number(year), Number(month) - 1, Number(day));
};

interface Timespan {
  startDate: Date;
  endDate: Date;
  label: string;
  check: (date: Date) => boolean;
}

export function getTimespans(referenceDate: Date = new Date()): Timespan[] {
  const today = new Date(referenceDate);
  today.setHours(0, 0, 0, 0);

  const lastFiveDaysStart = new Date(today);
  lastFiveDaysStart.setDate(lastFiveDaysStart.getDate() - 4);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const thisWeekStart = new Date(today);
  thisWeekStart.setDate(
    thisWeekStart.getDate() - ((thisWeekStart.getDay() + 6) % 7)
  );

  const lastWeekStart = new Date(thisWeekStart);
  lastWeekStart.setDate(lastWeekStart.getDate() - 7);

  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);

  const lastMonthStart = new Date(thisMonthStart);
  lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);

  const nextDay = (date: Date): Date => {
    const next = new Date(date);
    next.setDate(next.getDate() + 1);
    return next;
  };

  const nextWeek = (date: Date): Date => {
    const next = new Date(date);
    next.setDate(next.getDate() + 7);
    return next;
  };

  const nextMonth = (date: Date): Date => {
    const next = new Date(date);
    next.setMonth(next.getMonth() + 1);
    return next;
  };

  const createTimespan = (
    startDate: Date,
    endDate: Date,
    label: string
  ): Timespan => ({
    startDate,
    endDate,
    label,
    check: (date: Date) => date >= startDate && date < endDate,
  });

  return [
    createTimespan(today, nextDay(today), "Heute"),
    createTimespan(yesterday, today, "Gestern"),
    createTimespan(thisWeekStart, nextWeek(thisWeekStart), "Diese Woche"),
    createTimespan(lastWeekStart, thisWeekStart, "Letzte Woche"),
    createTimespan(thisMonthStart, nextMonth(thisMonthStart), "Dieser Monat"),
    createTimespan(lastMonthStart, thisMonthStart, "Letzter Monat"),
    createTimespan(lastFiveDaysStart, nextDay(today), "temp_last_5_days"),
  ];
}
