/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext } from "react";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import TableWidget from "../../helpers/tableBuilder";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

// The header mapping for the details table in the popup
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  date: {
    name: "Datum",
    type: "date",
    digits: 0,
    attach: "",
  },
  customers: {
    name: "Kunden",
    type: "string",
    digits: 0,
    attach: "",
  },
  ad_sends: {
    name: "Ad-Sends",
    type: "number",
    digits: 0,
    attach: "",
  },
  send_count: {
    name: "Push-Sends",
    type: "number",
    digits: 0,
    attach: "",
  },
  recipients_real: {
    name: "Empfänger",
    type: "number",
    digits: 0,
    attach: "",
  },
  delivered: {
    name: "Zugestellt",
    type: "number",
    digits: 0,
    attach: "",
  },
  clicks: {
    name: "Klicks",
    type: "number",
    digits: 0,
    attach: "",
  },
  unsubscribed: {
    name: "Abmelder",
    type: "number",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  turnover: {
    name: "Umsatz",
    type: "number",
    digits: 0,
    attach: "€",
  },
  /*  roi_30: {
                                                name: "ROI30",
                                                type: "number",
                                                digits: 2,
                                                attach: "%",
                                              },*/
  rpm: {
    name: "RPM (Zugestellt)",
    type: "number",
    digits: 2,
    attach: "€",
  },
  rpmc: {
    name: "RPMC (Klicks)",
    type: "number",
    digits: 2,
    attach: "€",
  },
};

type MappedData = {
  date: string;
  customers: string;
  ad_sends: number;
  send_count: number;
  recipients_real: number;
  delivered: number;
  clicks: number;
  unsubscribed: number;
  leads: number;
  turnover: number;
  sum_anfo_30d: number;
  roi_30: number;
  rpm: number;
  rpmc: number;
};

const getData: (filterState: IFilterState) => Promise<MappedData[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
    customer: filterState.customers,
  };
  const apiData = (await fetchData<Record<string, any>[]>(
    "/api/push/grouped-by-day",
    options
  )) as Record<string, any>[];

  //map all data to one array

  return apiData.map((item) => {
    return {
      date: item.date,
      customers: item.customers,
      ad_sends: item.ad_sends,
      send_count: item.send_count,
      recipients_real: item.recipients_real,
      delivered: item.delivered,
      clicks: item.clicks,
      unsubscribed: item.unsubscribed,
      leads: item.leads,
      turnover: item.turnover,
      sum_anfo_30d: item.sum_anfo_30d,
      roi_30: item.leads
        ? (item.sum_anfo_30d / (item.leads * filterState.leadPrice)) * 100
        : 0,
      rpm: item.delivered ? (item.turnover / item.delivered) * 1000 : 0,
      rpmc: item.clicks ? (item.turnover / item.clicks) * 1000 : 0,
    };
  });
};

const computeSummary = (data: MappedData[]): MappedData => {
  const { filterState } = useContext(FilterContext)!;
  // Initialize an object to hold our summary values
  const summary: MappedData = {
    date: "Summary",
    customers: "",
    ad_sends: 0,
    send_count: 0,
    recipients_real: 0,
    delivered: 0,
    clicks: 0,
    unsubscribed: 0,
    leads: 0,
    turnover: 0,
    sum_anfo_30d: 0,
    roi_30: 0,
    rpm: 0,
    rpmc: 0,
  };

  // Iterate over each data item and sum the values
  data.forEach((item) => {
    summary.ad_sends += Number(item.ad_sends);
    summary.send_count += Number(item.send_count);
    summary.recipients_real += Number(item.recipients_real);
    summary.delivered += Number(item.delivered);
    summary.clicks += Number(item.clicks);
    summary.unsubscribed += Number(item.unsubscribed);
    summary.leads += Number(item.leads);
    summary.turnover += Number(item.turnover);
    summary.sum_anfo_30d += Number(item.sum_anfo_30d);
  });
  // Recalculate derived metrics for the summary
  summary.roi_30 =
    summary.leads > 0
      ? summary.sum_anfo_30d / (summary.leads * filterState.leadPrice)
      : 0; // Assuming 'leadPrice' should be factored in, adjust as necessary
  summary.rpm =
    summary.delivered > 0 ? (summary.turnover / summary.delivered) * 1000 : 0;
  summary.rpmc =
    summary.clicks > 0 ? (summary.turnover / summary.clicks) * 1000 : 0;

  return summary;
};
export const PushSendsPerDay: React.FC = () => {
  return (
    <TableWidget<MappedData>
      getData={getData}
      computeSummary={computeSummary}
      detailsHeaderMapping={detailsHeaderMapping}
      initialSortColumn="date"
      excelDownloadName="Push-Auswertung"
    />
  );
};
export default PushSendsPerDay;
