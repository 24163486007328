// ./react-app/src/contexts/gurupress/GurupressFilterContext.tsx
import React, { createContext, useState } from "react";

export type Source = {
  [key: string]: string;
};

export interface IGurupressFilterState {
  startDate: Date | null;
  endDate: Date | null;
  leadSource: string | undefined;
  sourceData: Source;
}

export const GurupressFilterContext = createContext<{
  filterState: IGurupressFilterState;
  setFilterState: React.Dispatch<React.SetStateAction<IGurupressFilterState>>;
} | null>(null);

export const FilterProvider: React.FC<
  React.PropsWithChildren<Record<string, never>>
> = ({ children }) => {
  const [filterState, setFilterState] = useState<IGurupressFilterState>({
    startDate: null,
    endDate: null,
    leadSource: undefined,
    sourceData: {},
  });

  return (
    <GurupressFilterContext.Provider value={{ filterState, setFilterState }}>
      {children}
    </GurupressFilterContext.Provider>
  );
};
