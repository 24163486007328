/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { formatNumber } from "../../helpers/numberUtils";
import { exportToExcel } from "../../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../../helpers/sortingsFuncs";
import LoadingSpinner from "../LoadingSpinner";
import { visuallyHidden } from "@mui/utils";
import { fetchArticleData } from "../../helpers/articleData";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

// The header mapping for the details table in the popup
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  time: {
    name: "Uhrzeit (Stunde)",
    type: "number",
    digits: 0,
    attach: "",
  },
  num_posts: {
    name: "Anzahl Artikel",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_views_ft: {
    name: "Avg Views (FT)",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_views_ga: {
    name: "Avg Views (GA)",
    type: "number",
    digits: 0,
    attach: "",
  },
  avg_leads: {
    name: "Avg Leads",
    type: "number",
    digits: 2,
    attach: "",
  },
};

type ApiResponse = {
  hour: number;
  post_count: number;
  ft_views: number;
  ga_views: number;
  leads: number;
};

type MappedData = {
  time: number;
  num_posts: number;
  avg_views_ft: number;
  avg_views_ga: number;
  avg_leads: number;
  sum_views_ft: number;
  sum_views_ga: number;
  sum_leads: number;
};

const getData: (filterState: IFilterState) => Promise<MappedData[]> = async (
  filterState: IFilterState
) => {
  const data = await fetchArticleData(
    "/api/articles/evaluation/post-time",
    filterState
  );
  //calculate the average values
  return data.map((item: ApiResponse) => {
    return {
      time: item.hour,
      num_posts: item.post_count,
      sum_views_ft: item.ft_views,
      sum_views_ga: item.ga_views,
      sum_leads: item.leads,
      avg_views_ft: item.ft_views / item.post_count,
      avg_views_ga: item.ga_views / item.post_count,
      avg_leads: item.leads / item.post_count,
    };
  });
};
export const PostTimeEvaluation: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof MappedData>("time");
  const [data, setData] = useState<MappedData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(detailsHeaderMapping);

  const handleRequestSort = (property: keyof MappedData) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof MappedData) => () => {
    handleRequestSort(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  // Handle search and sort before pagination
  const handleSort = (data: MappedData[]) => {
    // Sort the filtered data
    return sortedRowInformation<MappedData>(
      data,
      getComparator(order, orderBy)
    );
  };

  const computeSummary = (data: MappedData[]): MappedData => {
    const summaryData: MappedData = {
      time: 0,
      num_posts: 0,
      avg_views_ft: 0,
      avg_views_ga: 0,
      avg_leads: 0,
      sum_views_ft: 0,
      sum_views_ga: 0,
      sum_leads: 0,
    };
    data.forEach((item) => {
      summaryData.time += item.time;
      summaryData.num_posts += item.num_posts;
      summaryData.sum_views_ft += item.sum_views_ft;
      summaryData.sum_views_ga += item.sum_views_ga;
      summaryData.sum_leads += item.sum_leads;
    });
    summaryData.avg_views_ft = summaryData.sum_views_ft / summaryData.num_posts;
    summaryData.avg_views_ga = summaryData.sum_views_ga / summaryData.num_posts;
    summaryData.avg_leads = summaryData.sum_leads / summaryData.num_posts;
    return summaryData;
  };

  const summaryData = computeSummary(data);

  // Get the sorted and filtered data
  const sortedAndFilteredData = handleSort(data);

  // Pagination logic on the sorted and filtered data
  const paginatedData = sortedAndFilteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() => exportToExcel(data, "Artikel-Uhrzeit-Auswertung")}
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>
                    <TableSortLabel
                      active={orderBy === header}
                      direction={orderBy === header ? order : "asc"}
                      onClick={createSortHandler(header as keyof MappedData)}
                    >
                      {detailsHeaderMapping[header].name || header}
                      {orderBy === header ? (
                        <Box component="span" sx={{ ...visuallyHidden }}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRowInformation<MappedData>(
                paginatedData,
                getComparator(order, orderBy)
              ).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                    <TableCell key={key}>
                      {value.type === "number"
                        ? row[key as keyof MappedData] === null
                          ? ""
                          : formatNumber(
                              Number(row[key as keyof MappedData]),
                              value.digits
                            ) + (value.attach || "")
                        : row[key as keyof MappedData]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    sx={{ fontWeight: "bold", fontSize: ".8rem" }}
                  >
                    {detailsHeaderMapping[header].type === "number"
                      ? formatNumber(
                          Number(summaryData[header as keyof MappedData]),
                          detailsHeaderMapping[header as keyof MappedData]
                            .digits
                        ) +
                        (detailsHeaderMapping[header as keyof MappedData]
                          .attach || "")
                      : summaryData[header as keyof MappedData]}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={sortedAndFilteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};
export default PostTimeEvaluation;
