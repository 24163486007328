import React, { FC, useContext, useEffect, useState } from "react";
import {
  GurupressFilterContext,
  IGurupressFilterState,
} from "../../contexts/gurupress/GurupressFilterContext";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import fetchData from "../../helpers/fetchData";
import LoadingSpinner from "../LoadingSpinner";
import { formatGerman } from "../../helpers/chartUtils";

interface ITurnoverResponse {
  members: number;
  turnover: number;
  month: number;
}

type CohortData = {
  [key: string]: ITurnoverResponse[];
};

const getAndProcessLeadSourceData = async (
  filterState: IGurupressFilterState
): Promise<CohortData> => {
  const start_date = filterState.startDate
    ? filterState.startDate.toISOString().split("T")[0]
    : "2023-01-01";
  const end_date = filterState.endDate
    ? filterState.endDate.toISOString().split("T")[0]
    : new Date().toISOString().split("T")[0];

  // Fetch data for each lead source and process it
  const promises = Object.keys(filterState.sourceData).map((sourceKey) =>
    fetchData<ITurnoverResponse[]>("/api/gurupress/lead-turnover", {
      start_date,
      end_date,
      lead_source: sourceKey,
    }).then((data) => {
      data = data as ITurnoverResponse[];
      return {
        [sourceKey]: data.reduce(
          (acc, curr) => ({ ...acc, [curr.month]: curr }),
          {}
        ),
      };
    })
  );

  const allData = await Promise.all(promises);
  // Merge all data into one object
  return Object.assign({}, ...allData);
};

const getAndProcessData = async (filterState: IGurupressFilterState) => {
  const options = {
    start_date: filterState.startDate
      ? filterState.startDate.toISOString().split("T")[0]
      : "2023-01-01",
    end_date: filterState.endDate
      ? filterState.endDate.toISOString().split("T")[0]
      : new Date(),
    lead_source: filterState.leadSource,
  };

  const data = await fetchData<ITurnoverResponse[]>(
    "/api/gurupress/lead-turnover",
    options
  );
  return data as ITurnoverResponse[];
};

export const LeadTurnoverTable: FC = () => {
  const { filterState } = useContext(GurupressFilterContext)!;
  const [loading, setLoading] = useState<boolean>(true);
  const [turnoverData, setTurnoverData] = useState<ITurnoverResponse[]>([]);

  useEffect(() => {
    setLoading(true);
    getAndProcessData(filterState).then((data) => {
      setTurnoverData(data);
      setLoading(false);
    });
  }, [filterState]);

  let cumulativeTurnover = 0;
  let cumulativeAvgTurnover = 0;

  return loading ? (
    <LoadingSpinner />
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Monat</TableCell>
            <TableCell align="right">Subscriber</TableCell>
            <TableCell align="right">Umsatz in Monat</TableCell>
            <TableCell align="right">Ø Umsatz in Monat je Subscriber</TableCell>
            <TableCell align="right">Kumulierter Umsatz</TableCell>
            <TableCell align="right">Kumulierter Ø Umsatz</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {turnoverData.map((row) => {
            cumulativeTurnover += row.turnover;
            const averageTurnover = row.turnover / row.members;
            cumulativeAvgTurnover += averageTurnover;
            return (
              <TableRow key={row.month}>
                <TableCell component="th" scope="row">
                  {row.month + 1}
                </TableCell>
                <TableCell align="right">
                  {formatGerman.format(row.members)}
                </TableCell>
                <TableCell align="right">
                  {formatGerman.format(row.turnover)} €
                </TableCell>
                <TableCell align="right">
                  {formatGerman.format(averageTurnover)} €
                </TableCell>
                <TableCell align="right">
                  {formatGerman.format(cumulativeTurnover)} €
                </TableCell>
                <TableCell align="right">
                  {formatGerman.format(cumulativeAvgTurnover)} €
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const LeadCohortTable: FC = () => {
  const { filterState } = useContext(GurupressFilterContext)!;
  const [loading, setLoading] = useState<boolean>(true);
  const [cohortData, setCohortData] = useState<CohortData>({});

  useEffect(() => {
    setLoading(true);
    getAndProcessLeadSourceData(filterState).then((data) => {
      setCohortData(data);
      setLoading(false);
    });
  }, [filterState]);

  const range = (n: number) => Array.from({ length: n }, (_, i) => i);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Lead Source</TableCell>
            {range(6).map((i) => (
              <TableCell key={i} align="right">
                Monat {i + 1}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(cohortData).map((leadSource: string) => (
            <TableRow key={leadSource}>
              <TableCell component="th" scope="row">
                {filterState.sourceData[leadSource]}
              </TableCell>
              {range(6).map((i) => (
                <TableCell key={i} align="right">
                  {cohortData[leadSource][i]
                    ? formatGerman.format(
                        cohortData[leadSource][i].turnover /
                          cohortData[leadSource][i].members
                      ) + " €"
                    : ""}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
