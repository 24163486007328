/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import {
  HeaderGroup,
  TableState,
  useFilters,
  useSortBy,
  useTable,
} from "react-table";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Box,
  Button,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { formatNumber } from "../../helpers/numberUtils";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { exportToExcel } from "../../helpers/exportFuncs";

export interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
  showRelative?: boolean;
}

type Post = {
  post_id: number;
  isin_str: string;
  author_id: number;
  post_title: string;
  post_excerpt: string;
  post_date: string;
  post_status: string;
  permalink: string;
  views: number;
  ga_views: number;
  cached_at: string;
  first_isin: string;
  word_count: number;
  asset_name: string | null;
  author_name: string;
  leads: number;
  turnover: number;
};

type ExtendedTableState = TableState<Post> & {
  sortBy?: { id: string; desc: boolean }[];
};

// The header mapping for the details table in the popup
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  post_id: {
    name: "ID",
    type: "string",
    digits: 0,
    attach: "",
  },
  post_title: {
    name: "Titel",
    type: "string",
    digits: 0,
    attach: "",
  },
  post_date: {
    name: "Datum",
    type: "string",
    digits: 0,
    attach: "",
  },
  author_name: {
    name: "Autor",
    type: "string",
    digits: 0,
    attach: "",
  },
  asset_name: {
    name: "Aktie/Asset",
    type: "string",
    digits: 0,
    attach: "",
  },
  word_count: {
    name: "Wortanzahl",
    type: "number",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  turnover: {
    name: "Umsatz",
    type: "number",
    digits: 0,
    attach: "€",
  },
  avg_session_duration: {
    name: "Ø Session-Dauer",
    type: "number",
    digits: 0,
    attach: "Sek",
  },
  views: {
    name: "FT Views",
    type: "number",
    digits: 0,
    attach: "",
  },
  ga_views: {
    name: "GA Views Total",
    type: "number",
    digits: 0,
    attach: "",
  },
  organic_views: {
    name: "Org. Views",
    type: "number",
    digits: 0,
    attach: "",
  },
  unassigned_views: {
    name: "Unb. Views",
    type: "number",
    digits: 0,
    attach: "",
  },
  direct_views: {
    name: "Dir. Views",
    type: "number",
    digits: 0,
    attach: "",
  },
  referral_views: {
    name: "Ref. Views",
    type: "number",
    digits: 0,
    attach: "",
  },
  google_combined: {
    name: "V. Google (Alle)",
    type: "number",
    digits: 0,
    attach: "",
  },
  google_discover: {
    name: "V. Google Discover",
    type: "number",
    digits: 0,
    attach: "",
  },
  google_search: {
    name: "V. Google Search",
    type: "number",
    digits: 0,
    attach: "",
  },
  google_news: {
    name: "V. Google News",
    type: "number",
    digits: 0,
    attach: "",
  },
  finanztrends: {
    name: "V. Finanztrends",
    type: "number",
    digits: 0,
    attach: "",
  },
  bing: {
    name: "V. Bing",
    type: "number",
    digits: 0,
    attach: "",
  },
  other: {
    name: "V. Sonstige",
    type: "number",
    digits: 0,
    attach: "",
  },
  none: {
    name: "V. Direkt/Unbekannt",
    type: "number",
    digits: 0,
    attach: "",
  },
};

const getData = async (
  options: Record<string, string | number>,
  leadValue: number
) => {
  let detailsData = (await fetchData<Post[]>(
    "/api/articles/all",
    options
  )) as Post[];
  if (leadValue > 0) {
    detailsData = detailsData.map((item) => {
      item.turnover = Number(item.leads) * leadValue;
      return item;
    });
  }
  return detailsData;
};

export interface DetailsTableProps {
  startDate: Date;
  endDate: Date;
  articleType: string;
  leadValue: number;
  authorId?: number;
  assetId?: number;
}

const DetailsTable: React.FC<DetailsTableProps> = (props) => {
  // Use props here...
  const { startDate, endDate, articleType, leadValue, authorId, assetId } =
    props;
  const columns = useMemo(
    () =>
      Object.entries(detailsHeaderMapping).map(
        ([key, { name, type, digits, attach }]) => ({
          Header: name,
          accessor: key as keyof Post,
          Filter:
            type === "string"
              ? ({ column: { filterValue, setFilter } }: any) => (
                  <Input
                    value={filterValue || ""}
                    onChange={(e) => setFilter(e.target.value || undefined)}
                    placeholder={`Suche in ${name}`}
                  />
                )
              : "",
          Cell: ({ value }: any) => {
            if (type === "number") {
              return `${formatNumber(value, digits)} ${attach}`;
            } else if (name === "ID") {
              //add link to article
              return (
                <a
                  href={`https://www.finanztrends.de/?p=${value}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {value}
                </a>
              );
            }
            return value;
          },
        })
      ),
    []
  );

  const [data, setData] = useState<Post[]>([]);

  useEffect(() => {
    const fetchTableData = async () => {
      const options: Record<string, string | number> = {
        start_date: dateToSqlDate(startDate),
        end_date: dateToSqlDate(endDate),
        article_type: articleType,
      };
      if (authorId) {
        options.author_id = authorId;
      }
      if (assetId) {
        options.asset_id = assetId;
      }
      const detailsData = await getData(options, leadValue);
      setData(detailsData);
    };

    fetchTableData();
  }, [startDate, endDate, articleType, leadValue, authorId, assetId]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [{ id: "post_title", desc: false }],
        } as ExtendedTableState,
      },
      useFilters,
      useSortBy
    );

  return (
    <Box>
      <Button
        onClick={() => exportToExcel(data, "Artikel-Detail-Auswertung")}
        sx={{ float: "right" }}
      >
        Download als Excel
      </Button>
      <TableContainer component={Paper}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: HeaderGroup<Post>) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDownwardIcon />
                        ) : (
                          <ArrowUpwardIcon />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                    {column && column.canFilter && column.render("Filter")}{" "}
                    {/* Add this line */}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface DetailsModalProps {
  open: boolean;
  onClose: () => void;
  startDate: Date;
  endDate: Date;
  articleType: string;
  leadValue: number;
  authorId?: number;
  assetId?: number;
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  open,
  onClose,
  ...tableProps
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <DetailsTable {...tableProps} />
      </DialogContent>
    </Dialog>
  );
};

export default DetailsModal;
