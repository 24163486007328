// ./react-app/src/components/NewsletterStatistics.tsx
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import fetchData from "../helpers/fetchData";
import { FilterContext, IFilterState } from "../contexts/FilterContext";
import { dateToSqlDate } from "../helpers/dateFuncs";
import LoadingSpinner from "./LoadingSpinner";
import { formatNumber } from "../helpers/numberUtils";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../helpers/sortingsFuncs";
import { exportToExcel } from "../helpers/exportFuncs";

type INewsletterStatisticsResponse = Record<string, string>;
type DataKey = keyof INewsletterStatisticsResponse;
type Data = Record<DataKey, string>;

// The header mapping
const headerMapping: Record<string, string> = {
  newsletter_title: "Name",
  total_sends: "Sends",
  total_recipients: "Empfänger",
  avg_recipients: "Ø Empfänger",
  total_opens: "Öffner",
  total_unique_opens: "Unique Öffner",
  avg_opens: "Ø Öffner",
  avg_unique_opens: "Ø Unique Öffner",
  avg_open_rate_unique: "Ø Öffnungsrate (Unique)",
  //total_clicks: "Klicks",
  //total_unique_clicks: "Unique Klicks",
  //avg_clicks: "Ø Klicks",
  //avg_click_rate: "Ø Klickrate",
  //avg_unique_clicks: "Ø Unique Klicks",
  unsubscribed: "Abmelder",
  avg_unsubscribed: "Ø Abmelder",
  unsub_rate: "Abmelderate (Unique Öffner)",
  //avg_soft_bounces: "Ø Soft Bounces",
  //avg_hard_bounces: "Ø Hard Bounces",
  //website_traffic: "Website Traffic",
  ad_clicks: "Ad-Klicks",
  avg_ad_clicks: "Ø Ad-Klicks",
  ad_click_rate: "Ad-Klickrate (Unique Öffner)",
  ad_click_rate_recipients: "Ad-Klickrate (Empfänger)",
  total_leads: "Leads",
  avg_leads: "Ø Leads",
  lead_rate: "Leadrate (Unique Öffner)",
  total_turnover: "Umsatz",
  avg_turnover: "Ø Umsatz",
  etkp: "ETKP",
};

const headers = Object.keys(headerMapping);

const getAndProcessData = async (filterState: IFilterState) => {
  if (!filterState.startDate) {
    throw new Error("No start date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: filterState.endDate ? dateToSqlDate(filterState.endDate) : "",
    sam_group_by: filterState.samGrouping,
    customer_id: filterState.customers,
  };

  return await fetchData<INewsletterStatisticsResponse>(
    "/api/newsletter-aggregated-statistics",
    options
  );
};

const NewsletterStatistics: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<DataKey>("campaign_name");
  const [data, setData] = useState<INewsletterStatisticsResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    setLoading(true);
    setData([]); // Clear the data state when filterState changes
    const fetchData = async () => {
      try {
        const data = await getAndProcessData(filterState);
        setData(Array.isArray(data) ? data : [data]);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Button onClick={() => exportToExcel(data, "Newsletter-Auswertung")}>
        Download als Excel
      </Button>
      <TableContainer
        component={Paper}
        key={JSON.stringify(filterState)} //force rerender on filter change
      >
        <Table sx={{ minWidth: 650 }} aria-label="Aggregierte Statistik">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={createSortHandler(header)}
                  >
                    {headerMapping[header] || header}
                    {orderBy === header ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<Data>(
              data,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {Object.entries(row).map(([key, value]) => {
                  // Check if the key is present in headerMapping -> as click parameters should be skipped
                  if (!headerMapping.hasOwnProperty(key) && key !== "name") {
                    return null;
                  }
                  return (
                    <TableCell key={key}>
                      {Number(value)
                        ? formatNumber(
                            Number(value),
                            [
                              "avg_open_rate_unique",
                              "avg_click_rate",
                              "avg_hard_bounces",
                              "unsub_rate",
                              "etkp",
                            ].includes(key)
                              ? 2
                              : [
                                  "ad_click_rate",
                                  "lead_rate",
                                  "ad_click_rate_recipients",
                                ].includes(key)
                              ? 3
                              : 0
                          ) +
                          ([
                            "avg_open_rate_unique",
                            "avg_click_rate",
                            "ad_click_rate",
                            "ad_click_rate_recipients",
                            "lead_rate",
                            "unsub_rate",
                          ].includes(key)
                            ? " %"
                            : [
                                "total_turnover",
                                "avg_turnover",
                                "etkp",
                              ].includes(key)
                            ? " €"
                            : "")
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default NewsletterStatistics;
