/* ./react-app/src/components/UnsubscribedLeadExportsTable */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatNumber } from "../../helpers/numberUtils";
import { exportToExcel } from "../../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import LoadingSpinner from "../LoadingSpinner";
import { fetchLeadsData } from "../../helpers/leadsData";
import { IResponseData } from "../../helpers/fetchData";
import moment from "moment";

type MappedData = {
  [key: string]: number | string;
  date: string;
};

const getData: (filterState: IFilterState) => Promise<MappedData[]> = async (
  filterState: IFilterState
) => {
  const response = await fetchLeadsData(
    "/api/lead-exports/unsubscribed",
    filterState
  );
  const data = Array.isArray(response) ? response : [response];

  const clientNames = Array.from(
    new Set(data.map((item: IResponseData) => item.client_name))
  );

  const groupData = (data: IResponseData[], groupBy: string) => {
    return data.reduce((acc: MappedData[], item: IResponseData) => {
      const date = moment(item.date);
      const currentYear = moment().year();
      let formattedDate = "";

      switch (groupBy) {
        case "daily":
          formattedDate = date.format("DD.MM.YYYY");
          break;
        case "weekly":
          formattedDate = `KW ${date.isoWeek()}`;
          if (date.year() < currentYear) {
            formattedDate += ` ${date.year()}`;
          }
          break;
        case "monthly":
          formattedDate = date.format("MMMM YYYY");
          break;
        case "yearly":
          formattedDate = date.format("YYYY");
          break;
      }

      const existingEntry = acc.find((entry) => entry.date === formattedDate);
      if (existingEntry) {
        existingEntry[item.client_name] =
          (existingEntry[item.client_name] as number) + item.leads;
      } else {
        const newEntry: MappedData = {
          date: formattedDate,
          ...Object.fromEntries(
            clientNames.map((name) => [
              name,
              item.client_name === name ? item.leads : 0,
            ])
          ),
        };
        acc.push(newEntry);
      }
      return acc;
    }, []);
  };

  const mappedData: MappedData[] = groupData(data, filterState.display);

  return mappedData;
};

export const UnsubscribedLeadExportsTable: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [data, setData] = useState<MappedData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(data[0] || {});

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() =>
            exportToExcel(data, "Exported-Unsubscriber-Leads-per-Client")
          }
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  {headers.map((header) => (
                    <TableCell key={header}>
                      {typeof row[header] === "number"
                        ? formatNumber(Number(row[header]), 0)
                        : row[header]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default UnsubscribedLeadExportsTable;
