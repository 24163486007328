/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { dateToSqlDate } from "../helpers/dateFuncs";
import fetchData from "../helpers/fetchData";
import { formatNumber } from "../helpers/numberUtils";
import { exportToExcel } from "../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../contexts/FilterContext";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../helpers/sortingsFuncs";
import LoadingSpinner from "./LoadingSpinner";
import { visuallyHidden } from "@mui/utils";
import { channelNameMapping } from "../helpers/nameMappings";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

// The header mapping for the details table in the popup
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  source: {
    name: "Kanal",
    type: "string",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  revenue: {
    name: "Umsatz",
    type: "number",
    digits: 0,
    attach: "€",
  },
  doi_rate: {
    name: "DOI Rate",
    type: "number",
    digits: 2,
    attach: "%",
  },
  roi_30: {
    name: "ROI (30d)",
    type: "number",
    digits: 2,
    attach: "%",
  },
  roi_rate: {
    name: "ROI (laufend)",
    type: "number",
    digits: 2,
    attach: "%",
  },
};

type LeadData = {
  source: any;
  leads: number | null;
  revenue: number | null;
  doi_rate: number | null;
  roi_rate: number | null;
  [key: string]: any;
};

type Data = Record<DataKey, string>;
type DataKey = keyof LeadChannelData;
type LeadChannelData = Record<string, any>;
/*{
  source: string;
  leads: number;
  revenue: number;
  doi_rate: number;
  roi_rate: number;
};*/

const getData: (filterState: IFilterState) => Promise<LeadData[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
    customer_id: filterState.customers,
    group_by: "source",
  };
  const leadsData = (await fetchData<Record<string, any>[]>(
    "/api/leads",
    options
  )) as Record<string, any>[];
  const availableSources = leadsData.map((item) => item.source);
  const revenueData = (await fetchData<Record<string, any>[]>(
    "/api/lead-revenue",
    options
  )) as Record<string, any>[];
  const roiRateData = (await fetchData<Record<string, any>[]>(
    "/api/vnr/roi-rate",
    options
  )) as Record<string, any>[];
  //loop over all available sources and get doi rate for each source
  const doiRateData = await Promise.all(
    availableSources.map(async (source) => {
      //source push has no doi rate as not tracked bc not SD system
      if (source === "push") {
        return {
          source: source,
          doi_rate: null,
        };
      }
      const doiData = (await fetchData<Record<string, any>>("/api/doi-rate", {
        ...options,
        source,
      })) as Record<string, any>;
      return {
        source: source,
        doi_rate: doiData.overallDoiRate,
      };
    })
  );
  //map all data to one array
  return leadsData.map((item) => {
    const revenueItem = revenueData.find(
      (revenueItem) => revenueItem.source === item.source
    );
    const doiRateItem = doiRateData.find(
      (doiRateItem) => doiRateItem.source === item.source
    );
    const roiRateItem = roiRateData.find(
      (roiRateItem) => roiRateItem.source === item.source
    );

    return {
      source: item.source,
      leads: parseInt(item.leads),
      revenue: parseFloat(revenueItem?.revenue) || null,
      doi_rate: (doiRateItem?.doi_rate as number) * 100 || null,
      roi_30: (roiRateItem?.roi_30 as number) * 100 || null,
      roi_rate: (roiRateItem?.roi_rate as number) * 100 || null,
    };
  });
};
export const LeadChannelsTable: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<DataKey>("leads");
  const [data, setData] = useState<LeadData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(detailsHeaderMapping);

  const computeSummary = (data: LeadData[]): LeadData => {
    const totalLeads = data.reduce((acc, curr) => acc + curr.leads! || 0, 0);
    const totalRevenue = data.reduce(
      (acc, curr) => acc + curr.revenue! || 0,
      0
    );
    const totalDoiRateWeighted = data
      .filter((d) => !isNaN(d.doi_rate!) && d.doi_rate !== null)
      .reduce((acc, curr) => acc + curr.doi_rate! * curr.leads!, 0);
    const avgDoiRate = totalLeads ? totalDoiRateWeighted / totalLeads : 0;

    const totalRoiRateWeighted = data
      .filter((d) => !isNaN(d.roi_rate!) && d.roi_rate !== null)
      .reduce((acc, curr) => acc + curr.roi_rate! * curr.leads!, 0);

    const totalRoi30Weighted = data
      .filter((d) => !isNaN(d.roi_30!) && d.roi_30 !== null)
      .reduce((acc, curr) => acc + curr.roi_30! * curr.leads!, 0);

    const avgRoi30 = totalLeads ? totalRoi30Weighted / totalLeads : 0;
    const avgRoiRate = totalLeads ? totalRoiRateWeighted / totalLeads : 0;

    return {
      source: "Gesamt",
      leads: totalLeads,
      revenue: totalRevenue,
      doi_rate: avgDoiRate,
      roi_30: avgRoi30,
      roi_rate: avgRoiRate,
    };
  };

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  const summaryData = computeSummary(data);

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() => exportToExcel(data, "Verkaufte-Leads-Auswertung")}
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={createSortHandler(header)}
                  >
                    {detailsHeaderMapping[header].name || header}
                    {orderBy === header ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<LeadData>(
              data,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                  <TableCell key={key}>
                    {value.type === "number"
                      ? row[key] === null
                        ? ""
                        : formatNumber(Number(row[key]), value.digits) +
                          (value.attach || "")
                      : key === "source"
                      ? channelNameMapping[row[key]] || row[key]
                      : row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header}
                  sx={{ fontWeight: "bold", fontSize: ".8rem" }}
                >
                  {detailsHeaderMapping[header].type === "number"
                    ? formatNumber(
                        Number(summaryData[header]),
                        detailsHeaderMapping[header].digits
                      ) + (detailsHeaderMapping[header].attach || "")
                    : summaryData[header]}
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default LeadChannelsTable;
