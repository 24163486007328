//./react-app/src/scenes/sold-leads/index.tsx
import Header from "../../components/Header";
import React, { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import SoldLeadsTable from "../../components/SoldLeadsTable";

const SoldLeads: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Verkaufte Leads"
          subtitle="Auswertungen für Leads aus E-Mail Marketing, Push etc."
        />
      </Box>
      <Box>
        <Typography variant="h5" color={colors.blueAccent[400]}>
          <p>
            Für die Kanäle Push, Website & Sonstige kann keine DOI-Rate
            errechnet werden, da die Anmeldungen nicht über SOI-Durchschleifung
            erfolgen und der VNR keine Auswertung zu gesammelten SOIs
            bereitstellt.
          </p>
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box>
          <Typography variant="h5" fontWeight="600">
            Lead-Daten gruppiert nach Channel
          </Typography>
          <Box height="250px" mt="-20px">
            <SoldLeadsTable />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SoldLeads;
