// ./react-app/src/components/DoiRatio.tsx
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useChartTheme } from "../themes/chartTheme";
import fetchData from "../helpers/fetchData";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import {
  generateColorMap,
  generateColors,
  ICustomerChart,
  mapCustomersToChartData,
} from "../helpers/chartUtils";
import { formatDateToDM, parseDate } from "../helpers/chartUtils";
import { FilterContext, IFilterState } from "../contexts/FilterContext";

interface IDoiRatePerDateAndCustomer {
  overallDoiRate: number;
  doiRatePerDateAndCustomer: {
    [date: string]: {
      [customer: string]: number;
    };
  };
}

interface IDoiRate {
  x: string;
  y: number;
}

interface IDataResult {
  overallDoiRate: number;
  chartData: ICustomerChart<IDoiRate[]>[];
}

const getData = async (filterState: IFilterState) => {
  if (!filterState.startDate) {
    throw new Error("No start date provided");
  }
  const options = {
    start_date: filterState.startDate.toISOString().split("T")[0],
    end_date: filterState.endDate
      ? filterState.endDate.toISOString().split("T")[0]
      : "",
    customer_id: filterState.customers.length > 0 ? filterState.customers : [],
    group_by: [],
  };

  const rawData = (await fetchData<IDoiRatePerDateAndCustomer>(
    "/api/doi-rate",
    options,
    false
  )) as IDoiRatePerDateAndCustomer;

  const { doiRatePerDateAndCustomer } = rawData;

  // Find all unique customers
  let customers: string[] = [];
  for (const date in doiRatePerDateAndCustomer) {
    customers = [...customers, ...Object.keys(doiRatePerDateAndCustomer[date])];
  }
  customers = Array.from(new Set(customers)); // remove duplicates

  // Initialize data array with unique customers
  const data: ICustomerChart<IDoiRate[]>[] = mapCustomersToChartData<IDoiRate>(
    customers,
    filterState
  );

  for (const date in doiRatePerDateAndCustomer) {
    for (const customer_id in doiRatePerDateAndCustomer[date]) {
      // Find the matching customerChart
      const customerChart = data.find((d) => d.id === customer_id);

      if (customerChart) {
        // Push the new entry into the customerChart's data array
        customerChart.data.push({
          x: date,
          y: doiRatePerDateAndCustomer[date][customer_id],
        });
      }
    }
  }

  //console.dir(data);

  return {
    overallDoiRate: rawData.overallDoiRate,
    chartData: data,
  };
};

const DoiRatio: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartTheme = useChartTheme();
  const { filterState } = useContext(FilterContext)!;
  const [doiData, setData] = useState<IDataResult>();
  useEffect(() => {
    getData(filterState)
      .then(setData)
      .catch((error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);
  if (!doiData) {
    return <p>Loading...</p>; // Render some loading state or other placeholder
  }
  const chartData = doiData.chartData.map((d) => ({ ...d, id: d.name }));
  const lineColors = new Map(chartData.map((d) => [d.id, d.color]));
  return (
    <>
      <Box
        position="absolute"
        top={10}
        right={10}
        bgcolor={colors.grey[100]}
        color={colors.grey[900]}
        boxShadow={1}
        p={1}
        borderRadius={1}
      >
        Overall DOI Rate: {(doiData.overallDoiRate * 100).toFixed(2)}%
      </Box>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: "auto", max: "auto", stacked: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Datum",
          legendPosition: "middle",
          legendOffset: 32,
          format: (d) => {
            const date = parseDate(d);
            return date ? formatDateToDM(date) : "";
          },
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "DOI Rate",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={8}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        theme={chartTheme}
        // Replace existing color definition
        colors={(d: ICustomerChart<IDoiRate[]>): string =>
          d.id === "overall" ? "black" : "#" + lineColors.get(d.id) || "black"
        } // 'overall' line will be black
        lineWidth={4}
        enableGridX={false}
        enableGridY={false}
        tooltip={({ point }) => (
          <Box
            sx={{
              padding: "5px 10px",
              color: "#222",
              background: "#e0e0e0",
              boxShadow: "0 0 2px #222",
            }}
          >
            <strong>{point.serieId}</strong>:{" "}
            {((point.data.yFormatted as number) * 100).toFixed(2)}%
          </Box>
        )}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default DoiRatio;
