/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { exportToExcel } from "./exportFuncs";
import { FilterContext, IFilterState } from "../contexts/FilterContext";
import { getComparator, Order, sortedRowInformation } from "./sortingsFuncs";
import LoadingSpinner from "../components/LoadingSpinner";
import { visuallyHidden } from "@mui/utils";
import { formatDataValue } from "./formatFuncs";

export interface HeaderMappingItem {
  name: string;
  type: string;
  digits?: number;
  attach?: string;
}

interface TableWidgetProps<T> {
  getData: (filterState: IFilterState) => Promise<T[]>;
  computeSummary?: (data: T[]) => T;
  detailsHeaderMapping: Record<string, HeaderMappingItem>;
  initialSortColumn: keyof T;
  searchByColumn?: keyof T;
  excelDownloadName: string;
  sortOrder?: Order;
}

const TableWidget = <T,>({
  getData,
  computeSummary,
  detailsHeaderMapping,
  initialSortColumn,
  searchByColumn,
  excelDownloadName,
  sortOrder = "asc",
}: TableWidgetProps<T>): React.ReactElement => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>(sortOrder);
  const [orderBy, setOrderBy] = useState<keyof T>(initialSortColumn);
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(detailsHeaderMapping);

  const handleRequestSort = (property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof T) => () => {
    handleRequestSort(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // Handle search and sort before pagination
  const handleSearchAndSort = (data: T[]) => {
    if (searchByColumn) {
      // Filter data based on search query
      data = data.filter((item) =>
        String(item[searchByColumn])
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    // Sort the filtered data
    return sortedRowInformation<T>(data, getComparator(order, orderBy));
  };

  const summaryData = computeSummary ? computeSummary(data) : null;

  // Get the sorted and filtered data
  const sortedAndFilteredData = handleSearchAndSort(data);

  // Pagination logic on the sorted and filtered data
  const paginatedData = sortedAndFilteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  console.dir(data);
  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        {searchByColumn && (
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ float: "right" }}
          />
        )}
        <Button
          onClick={() => exportToExcel(data, excelDownloadName)}
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>
                    <TableSortLabel
                      active={orderBy === header}
                      direction={orderBy === header ? order : "asc"}
                      onClick={createSortHandler(header as keyof T)}
                    >
                      {detailsHeaderMapping[header].name || header}
                      {orderBy === header ? (
                        <Box component="span" sx={{ ...visuallyHidden }}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRowInformation<T>(
                paginatedData,
                getComparator(order, orderBy)
              ).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                    <TableCell key={key}>
                      {formatDataValue(
                        row[key as keyof T],
                        detailsHeaderMapping[key].type,
                        detailsHeaderMapping[key].digits,
                        detailsHeaderMapping[key].attach
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            {summaryData && (
              <TableFooter>
                <TableRow>
                  {headers.map((key) => (
                    <TableCell
                      key={key}
                      sx={{ fontWeight: "bold", fontSize: ".8rem" }}
                    >
                      {formatDataValue(
                        summaryData[key as keyof T],
                        detailsHeaderMapping[key].type,
                        detailsHeaderMapping[key].digits,
                        detailsHeaderMapping[key].attach
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={sortedAndFilteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default TableWidget;
