//./react-app/src/scenes/sold-leads/index.tsx
import Header from "../../components/Header";
import React, { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ProductStatisticsTable from "../../components/adserver/ProductStatistics";

const ProductStatisticsPage: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Report Statistik"
          subtitle="Auswertungen zu SAMs & Ads für alle AdServer Produkte"
        />
      </Box>
      <Box>
        <Typography variant="h5" color={colors.blueAccent[400]}>
          <p>
            Der ROI-Wert ist <u>nicht</u> der ROI-30 sondern der laufende ROI,
            der ROI-30 kann stand jetzt mit den gelieferten Daten des VNR nicht
            berechnet werden.
          </p>
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box>
          <Box height="250px" mt="-20px">
            <ProductStatisticsTable />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductStatisticsPage;
