// ./react-app/src/helpers/colorUtils.ts
import { timeFormat, timeParse } from "d3-time-format";
import { Customer, IFilterState } from "../contexts/FilterContext";

export const formatGerman = new Intl.NumberFormat("de-DE");

export function generateColors(count: number): string[] {
  const colors: string[] = [];

  for (let i = 0; i < count; i++) {
    const hue = Math.round(360 * (i / count));
    colors.push(`hsl(${hue}, 100%, 50%)`);
  }

  return colors;
}

export function generateColorMap(ids: string[]): Map<string, string> {
  const colorMap: Map<string, string> = new Map();
  const count = ids.length;
  for (let i = 0; i < count; i++) {
    const hue = Math.round(360 * (i / count));
    colorMap.set(ids[i], `hsl(${hue}, 100%, 50%)`);
  }
  return colorMap;
}

export interface ICustomerChart<T> {
  id: string;
  name: string;
  data: T;
  color: string;
}

export const mapCustomersToChartData = <T>(
  customerIds: string[],
  filterState: IFilterState
): ICustomerChart<T[]>[] => {
  return customerIds.map((customerId) => {
    const customerData = filterState.customerData.find(
      (item: Customer) => item.id === customerId
    );
    return {
      id: customerId,
      name: customerData ? customerData.name : customerId, // use customer name
      data: [],
      color: customerData ? customerData.chart_color : "black", // use customer color
    };
  });
};

export const parseDate = timeParse("%Y-%m-%d");
export const formatDateToDM = timeFormat("%d.%m.");
