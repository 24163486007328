import { formatNumber } from "./numberUtils";

export const formatDataValue = (
  value: any,
  type: string,
  digits = 0,
  attach = ""
): string => {
  if (type === "number") {
    return formatNumber(value, digits) + attach;
  } else if (type === "percentage") {
    return formatNumber(value * 100, digits) + attach;
  } else {
    return String(value);
  }
};
