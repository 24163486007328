//./react-app/src/scenes/dashboard/index.tsx
import Header from "../../components/Header";
import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import LeadsChart from "../../components/LeadsChart";
import DoiRatio from "../../components/DoiRatio";
import MonthlyListGrowthChart from "../../components/MonthlyListGrowthChart";

const Dashboard: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header title="Dashboard" subtitle="Übersicht über alle Statistiken" />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            E-Mail Marketing Leads
          </Typography>
          <Box height="250px" mt="-20px">
            <LeadsChart />
          </Box>
        </Box>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            E-Mail Marketing DOI-Rate ( Klick in E-Mail zu DOI )
          </Typography>
          <Box height="250px" mt="-20px">
            <DoiRatio />
          </Box>
        </Box>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            List-Entwicklung
          </Typography>
          <Box height="250px" mt="-20px">
            <MonthlyListGrowthChart />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
