//./react-app/src/scenes/vnr/roi-evaluation.tsx
import Header from "../../components/Header";
import React, { FC, useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import RoiPerMonth from "../../components/vnr/RoiPerMonth";
import moment from "moment/moment";
import { FilterContext } from "../../contexts/FilterContext";
import RoiPerSource from "../../components/vnr/RoiPerSource";
import RoiPerAsset from "../../components/vnr/RoiPerAsset";
import RoiPerEmailProvider from "../../components/vnr/RoiPerEmailProvider";

const RoiEvaluationPage: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { filterState } = useContext(FilterContext)!;
  filterState.startDate = moment()
    .subtract(1, "year")
    .subtract(1, "month")
    .toDate();
  filterState.endDate = moment().subtract(1, "month").toDate();
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="ROI Auswertung"
          subtitle="Das Enddatum ist automatisch um 30 Tage zurückgesetzt um einen sauberen ROI Wert abbilden zu können"
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Gruppierung nach Jahr/Monat
        </Typography>
        <Box height="250px" mt="-20px">
          <RoiPerMonth />
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Gruppierung nach Kanal/Quelle
        </Typography>
        <Box mt="-20px">
          <RoiPerSource />
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Gruppierung nach Asset/Aktie
        </Typography>
        <Box mt="-20px">
          <RoiPerAsset />
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Gruppierung nach E-Mail Provider
        </Typography>
        <Box mt="-20px">
          <RoiPerEmailProvider />
        </Box>
      </Box>
    </Box>
  );
};

export default RoiEvaluationPage;
