//./react-app/src/helpers/sortingsFuncs.ts

export type Order = "asc" | "desc";

export function descendingComparator<T, Key extends keyof T>(
  a: T,
  b: T,
  orderBy: Key
): number {
  // Safely assuming both a[orderBy] and b[orderBy] are either string or number
  const aValue = isNaN(Number(a[orderBy])) ? a[orderBy] : Number(a[orderBy]);
  const bValue = isNaN(Number(b[orderBy])) ? b[orderBy] : Number(b[orderBy]);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function getComparator<T, Key extends keyof T>(
  order: Order,
  orderBy: Key
): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function sortedRowInformation<Data>(
  rowArray: Data[],
  comparator: (a: Data, b: Data) => number
) {
  const stabilizedRowArray = rowArray.map(
    (el, index) => [el, index] as [Data, number]
  );
  stabilizedRowArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedRowArray.map((el) => el[0]);
}
