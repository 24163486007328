// ./react-app/src/components/ListGrowthChart.tsx
import React, { useContext, useEffect, useState } from "react";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { useChartTheme } from "../../themes/chartTheme";
import fetchData from "../../helpers/fetchData";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import { formatDateToDM, parseDate } from "../../helpers/chartUtils";
import { tokens } from "../../theme";
import {
  getISOWeek,
  getMonth,
  groupBy,
} from "../../helpers/displayGroupingFuncs";

interface IListGrowthDailyResponse {
  date: string;
  subscribed: number;
  still_subscribed: number;
  now_unsubscribed: number;
  now_cleaned: number;
}

const getData: (
  filterState: IFilterState
) => Promise<IListGrowthDailyResponse[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  return (await fetchData<Record<string, any>[]>(
    "/api/mailchimp/list-growth",
    options
  )) as IListGrowthDailyResponse[];
};

const aggregateData = (
  data: IListGrowthDailyResponse[],
  interval: "daily" | "weekly" | "monthly" | "yearly"
): BarDatum[] => {
  switch (interval) {
    case "daily":
      return data.map((item) => ({
        date: item.date,
        "immer noch angemeldet": item.still_subscribed,
        "inzwischen abgemeldet": item.now_unsubscribed,
        "inzwischen gecleaned": item.now_cleaned,
      }));
    case "weekly":
    case "monthly":
      const groupedData = groupBy(data, (item) => {
        // For weekly or monthly grouping, you would need to convert the date
        // to a week number or a month string depending on the interval
        // For simplicity, let's assume we have a function that does that:
        return interval === "weekly"
          ? getISOWeek(item.date)
          : getMonth(item.date);
      });
      return Object.entries(groupedData).map(([date, items]) => ({
        date,
        "immer noch angemeldet": items.reduce(
          (sum, curr) => sum + curr.still_subscribed,
          0
        ),
        "inzwischen abgemeldet": items.reduce(
          (sum, curr) => sum + curr.now_unsubscribed,
          0
        ),
        "inzwischen gecleaned": items.reduce(
          (sum, curr) => sum + curr.now_cleaned,
          0
        ),
      }));
    default:
      return [];
  }
};

const DailyListGrowthBarChart: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<BarDatum[]>([]);
  useEffect(() => {
    getData(filterState)
      .then((fetchedData) => {
        const transformedData = aggregateData(
          fetchedData,
          filterState.display || "daily"
        );
        setData(transformedData);
      })
      .catch((error: Error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);

  const getFormattedTick = (value: string | number) => {
    switch (filterState.display) {
      case "weekly":
        // Expecting value in format "2023-W01"
        return `KW ${String(value).split("-W")[1]}`; // KW is the German abbreviation for Kalenderwoche (calendar week)
      case "monthly":
        // Expecting value in format "2023-01"
        const [year, month] = String(value).split("-");
        return `${year}, ${month}`; // Displays as "2023, 01"
      case "daily":
      default:
        // Format the daily date to a more readable form
        const date = parseDate(String(value));
        return date ? formatDateToDM(date) : "";
    }
  };

  const chartTheme = useChartTheme();
  return (
    <ResponsiveBar
      data={data}
      theme={chartTheme}
      keys={Object.keys(data[0] || {}).filter((key) => key !== "date")}
      indexBy="date"
      margin={{ top: 50, right: 180, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: getFormattedTick,
      }}
      axisLeft={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enableGridX={false}
      enableGridY={false}
      enableLabel={true}
      label={(bar) => `${bar.value}`}
      labelFormat={(value) => `${value}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      // to display label on top of the bar and in bold letters
      layers={[
        "grid",
        "axes",
        "bars",
        ({ bars }) => {
          // calculate sums for each index value
          const sums: { [index: string]: number } = {};
          let lastId = "";
          bars.forEach((bar) => {
            if (sums[bar.data.indexValue as string] === undefined)
              sums[bar.data.indexValue as string] = 0;
            sums[bar.data.indexValue as string] += bar.data.value as number;
            lastId = bar.data.id as string;
          });
          return (
            <g>
              {bars.map((bar) => {
                // If the indexValue is the same as the previous one, return null (do not render text)
                if ((bar.data.id as string) !== lastId) {
                  return null;
                }
                return (
                  <text
                    key={bar.key}
                    x={bar.x + bar.width / 2}
                    y={bar.y - 15}
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fill: colors.grey[100],
                      fontWeight: "bold",
                    }}
                  >
                    {sums[bar.data.indexValue as string]}
                  </text>
                );
              })}
            </g>
          );
        },
        "legends",
      ]}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default DailyListGrowthBarChart;
