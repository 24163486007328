//./react-app/src/scenes/dashboard/index.tsx
import Header from "../../components/Header";
import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import NewsletterStatistics from "../../components/NewsletterStatistics";

const Newsletter: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header title="Newsletter" subtitle="Aggregierte Kampagnenstatistik" />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box height="250px" mt="-20px">
            <NewsletterStatistics />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Newsletter;
