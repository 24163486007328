import React, { useEffect, useState } from "react";
import DailyControllingEmailGenerator from "../services/DailyControllingEmailGenerator";

const DailyControllingEmail: React.FC = () => {
  const [emailContent, setEmailContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const generateEmail = async () => {
      try {
        setLoading(true);
        const generator = new DailyControllingEmailGenerator(
          "ft",
          new Date(new Date().setMonth(new Date().getMonth() - 4)),
          new Date(),
          "new"
        );
        const content = await generator.generateEmail();
        setEmailContent(content);
      } catch (err) {
        setError("Failed to generate email content");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    generateEmail();
  }, []);

  if (loading) {
    return <div>Loading email content...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div dangerouslySetInnerHTML={{ __html: emailContent }} />;
};

export default DailyControllingEmail;
