// ./react-app/src/components/ListGrowthChart.tsx
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveLine, Serie } from "@nivo/line";
import fetchData from "../../helpers/fetchData";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  formatDateToDM,
  formatGerman,
  parseDate,
} from "../../helpers/chartUtils";
import {
  getISOWeek,
  getMonth,
  groupBy,
} from "../../helpers/displayGroupingFuncs";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface IListSizeDailyResponse {
  date: string;
  subscribed: number;
  active: number;
  inactive: number;
  unsubscribed: number;
  cleaned: number;
}

const displayMapping = {
  subscribed: "Subscriber",
  active: "Aktive",
  inactive: "Inaktive",
  unsubscribed: "Abmelder",
  cleaned: "Gecleaned",
};

const getData: (
  filterState: IFilterState
) => Promise<IListSizeDailyResponse[]> = async (filterState: IFilterState) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  return (await fetchData<Record<string, any>[]>(
    "/api/mailchimp/list-size",
    options
  )) as IListSizeDailyResponse[];
};

const aggregateData = (
  data: IListSizeDailyResponse[],
  interval: "daily" | "weekly" | "monthly" | "yearly",
  selectedDataType: keyof IListSizeDailyResponse
): Record<string, any>[] => {
  switch (interval) {
    case "daily":
      return data.map((item) => ({
        date: item.date,
        value: item[selectedDataType],
      }));
    case "weekly":
    case "monthly":
      const groupedData = groupBy(data, (item) => {
        // For weekly or monthly grouping, you would need to convert the date
        // to a week number or a month string depending on the interval
        // For simplicity, let's assume we have a function that does that:
        return interval === "weekly"
          ? getISOWeek(item.date)
          : getMonth(item.date);
      });
      return Object.entries(groupedData).map(([date, items]) => ({
        date,
        value: Math.round(
          items.reduce(
            (sum, curr) => sum + (curr[selectedDataType] as number),
            0
          ) / items.length
        ),
      }));
    default:
      return [];
  }
};

interface CustomPoint {
  serieId: string | number;
  data: {
    yFormatted: number | string;
    xFormatted?: number | string;
  };
}

const CustomTooltip = ({ point }: { point: CustomPoint }) => {
  return (
    <div
      style={{
        padding: "12px",
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    >
      {`${point.serieId}: ${formatGerman.format(
        point.data.yFormatted as number
      )}`}
    </div>
  );
};

const ListSizeLineChart: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<Serie[]>([]);
  const [selectedDataType, setSelectedDataType] = useState("subscribed");

  useEffect(() => {
    getData(filterState)
      .then((fetchedData) => {
        const transformedData = aggregateData(
          fetchedData,
          filterState.display || "daily",
          selectedDataType as keyof IListSizeDailyResponse
        );
        const lineData: Serie[] = [
          {
            id: displayMapping[selectedDataType as keyof typeof displayMapping],
            data: transformedData.map((item) => ({
              x: item.date,
              y: item.value,
            })),
            color: "#000000", // You might want to use different colors for different data types
          },
        ];
        console.dir(lineData);
        setData(lineData);
      })
      .catch((error: Error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState, selectedDataType]);

  const getFormattedTick = (value: string | number) => {
    switch (filterState.display) {
      case "weekly":
        // Expecting value in format "2023-W01"
        return `KW ${String(value).split("-W")[1]}`; // KW is the German abbreviation for Kalenderwoche (calendar week)
      case "monthly":
        // Expecting value in format "2023-01"
        const [year, month] = String(value).split("-");
        return `${year}, ${month}`; // Displays as "2023, 01"
      case "daily":
      default:
        // Format the daily date to a more readable form
        const date = parseDate(String(value));
        return date ? formatDateToDM(date) : "";
    }
  };

  return (
    <>
      <Box sx={{ position: "absolute", right: 20, marginTop: "-20px" }}>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Wert-Auswahl</InputLabel>
          <Select
            id="dataTypeSelect"
            value={selectedDataType}
            onChange={(e) => setSelectedDataType(e.target.value)}
          >
            {Object.keys(displayMapping).map((dataType) => (
              <MenuItem key={dataType} value={dataType}>
                {displayMapping[dataType as keyof typeof displayMapping]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <ResponsiveLine
        data={data}
        tooltip={CustomTooltip}
        colors={{ datum: "color" }}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          stacked: false,
          min: "auto",
          max: "auto",
        }}
        axisTop={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Datum",
          legendOffset: 36,
          legendPosition: "middle",
          format: getFormattedTick,
        }}
        axisLeft={{
          tickSize: 10,
          tickPadding: 5,
          tickRotation: 0,
          format: (d) => {
            return formatGerman.format(d);
          },
        }}
        lineWidth={3} // Default line width for subscribers and unsubscribes
        layers={[
          "grid",
          "markers",
          "axes",
          "areas",
          "crosshair",
          "lines",
          "points",
          "slices",
          "mesh",
          "legends",
        ]}
        enableSlices={false}
        useMesh={true}
        crosshairType="cross"
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default ListSizeLineChart;
