/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { formatNumber } from "../../helpers/numberUtils";
import { exportToExcel } from "../../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../../helpers/sortingsFuncs";
import LoadingSpinner from "../LoadingSpinner";
import { visuallyHidden } from "@mui/utils";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

// The header mapping for the details table in the popup
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  year_month: {
    name: "Jahr/Monat",
    type: "string",
    digits: 0,
    attach: "",
  },
  guru_leads: {
    name: "GP Leads",
    type: "string",
    digits: 0,
    attach: "",
  },
  fit_duplicates: {
    name: "FIT Dubletten",
    type: "number",
    digits: 0,
    attach: "",
  },
  fit_unsubbed: {
    name: "FIT Abmelder",
    type: "number",
    digits: 0,
    attach: "",
  },
};

type ApiData = {
  [key: string]: any;
};

type Data = Record<any, string>;
/*{
  source: string;
  leads: number;
  revenue: number;
  doi_rate: number;
  roi_rate: number;
};*/

const getData: (filterState: IFilterState) => Promise<ApiData[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
  };
  const data = (await fetchData<Record<string, any>[]>(
    "/api/gurupress/lead-imports-per-month",
    options
  )) as Record<string, any>[];

  //map all data to one array
  return data.map((item) => {
    return {
      year_month: item.year + "-" + item.month,
      guru_leads: parseInt(item.guru_leads),
      fit_duplicates: parseInt(item.fit_duplicates),
      fit_unsubbed: parseInt(item.fit_unsubbed),
    };
  });
};
export const ImportedLeadsTable: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>("year_month");
  const [data, setData] = useState<ApiData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(detailsHeaderMapping);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    // If sorting by year/month, convert to Date object for correct sorting
    if (property === "year_month") {
      setData(
        data.sort((a, b) => {
          const dateA = new Date(a[property]);
          const dateB = new Date(b[property]);
          return isAsc
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        })
      );
    } else {
      setData(
        data.sort(
          (a, b) => (a[property] < b[property] ? -1 : 1) * (isAsc ? 1 : -1)
        )
      );
    }
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() => exportToExcel(data, "Importierte-Leads-Auswertung")}
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={createSortHandler(header)}
                  >
                    {detailsHeaderMapping[header].name || header}
                    {orderBy === header ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<ApiData>(
              data,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                  <TableCell key={key}>
                    {value.type === "number"
                      ? row[key] === null
                        ? ""
                        : formatNumber(Number(row[key]), value.digits) +
                          (value.attach || "")
                      : row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ImportedLeadsTable;
