// ./react-app/src/components/LeadsChart.tsx
import React, { useEffect, useState, useContext } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import fetchData from "../helpers/fetchData";
import { useChartTheme } from "../themes/chartTheme";
import processData from "../helpers/processData";
import {
  formatDateToDM,
  ICustomerChart,
  mapCustomersToChartData,
  parseDate,
} from "../helpers/chartUtils";
import { FilterContext, IFilterState } from "../contexts/FilterContext";
import { dateToSqlDate } from "../helpers/dateFuncs";

interface IData {
  [key: string]: number | string;
}

interface ILeadsResponse {
  lead_date: string;
  campaign_id: number;
  send_date: string;
  client_id: number;
  ad_id: number | null;
  mailing_id: number | null;
  leads: number;
  product_id: number;
  customer_id: string;
  nl_id: number | null;
  non_opener_sam: string | null;
  ad_control_code: string | null;
}

const getAndProcessData = async (filterState: IFilterState) => {
  if (!filterState.startDate) {
    throw new Error("No start date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: filterState.endDate ? dateToSqlDate(filterState.endDate) : "",
    customer_id: filterState.customers.length > 0 ? filterState.customers : [],
    group_by: ["lead_date", "customer_id"],
  };

  const rawData = (await fetchData<ILeadsResponse>(
    "/api/leads",
    options
  )) as ILeadsResponse[];
  const data = processData<ILeadsResponse>(rawData, {
    dateKey: "lead_date",
    groupByKey: "customer_id",
    countKey: "leads",
  });
  // Get the unique customer ids from the data
  const customerIds = Array.from(
    new Set(rawData.map((item) => item.customer_id))
  );
  const customerCharts = mapCustomersToChartData<IData>(
    customerIds,
    filterState
  );

  return { data, customerCharts };
};

const LeadsChart: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { filterState } = useContext(FilterContext)!;
  const [data, setData] = useState<IData[]>([]);
  const [customerCharts, setCustomerCharts] = useState<
    ICustomerChart<IData[]>[]
  >([]);
  useEffect(() => {
    getAndProcessData(filterState)
      .then(({ data, customerCharts }) => {
        setData(data);
        setCustomerCharts(customerCharts);
      })
      .catch((error) => {
        // handle the error
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);

  const chartTheme = useChartTheme();
  const colorMap = new Map(customerCharts.map((item) => [item.id, item.color]));
  return (
    <ResponsiveBar
      data={data}
      theme={chartTheme}
      keys={Object.keys(data[0] || {}).filter((key) => key !== "date")}
      indexBy="date"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={(bar) => {
        return "#" + (colorMap.get(bar.id as string) || "000000");
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Datum",
        legendPosition: "middle",
        legendOffset: 32,
        format: (d) => {
          const date = parseDate(d);
          return date ? formatDateToDM(date) : "";
        },
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Leads",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableGridX={false}
      enableGridY={false}
      tooltip={({ id, value }) => (
        <Box
          sx={{
            padding: "5px 10px",
            color: "#222",
            background: "#e0e0e0",
            boxShadow: "0 0 2px #222",
          }}
        >
          <strong>
            {filterState.customerData.find((c) => c.id === id)?.name || "/"}
          </strong>
          : {value}
        </Box>
      )}
      enableLabel={true}
      label={(bar) => `${bar.value}`}
      labelFormat={(value) => `${value}`}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      // to display label on top of the bar and in bold letters
      layers={[
        "grid",
        "axes",
        "bars",
        ({ bars }) => {
          // calculate sums for each index value
          const sums: { [index: string]: number } = {};
          let lastId = "";
          bars.forEach((bar) => {
            if (sums[bar.data.indexValue as string] === undefined)
              sums[bar.data.indexValue as string] = 0;
            sums[bar.data.indexValue as string] += bar.data.value as number;
            lastId = bar.data.id as string;
          });
          return (
            <g>
              {bars.map((bar) => {
                // If the indexValue is the same as the previous one, return null (do not render text)
                if ((bar.data.id as string) !== lastId) {
                  return null;
                }
                return (
                  <text
                    key={bar.key}
                    x={bar.x + bar.width / 2}
                    y={bar.y - 15} // Position it slightly above the bar
                    textAnchor="middle"
                    dominantBaseline="central"
                    style={{
                      fill: colors.grey[100], // Use black color
                      fontWeight: "bold", // Make it bold
                    }}
                  >
                    {sums[bar.data.indexValue as string]}
                  </text>
                );
              })}
            </g>
          );
        },
      ]}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LeadsChart;
