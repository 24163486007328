// ./react-app/src/helpers/processData.ts

interface IProcessDataOptions {
  dateKey: string;
  groupByKey: string;
  countKey: string;
}

interface IResponseData {
  [key: string]: any; // The response can contain any key-value pairs
}

type DataItem = { [key: string]: number | string };

function processData<T extends IResponseData>(
  rawData: T[],
  options: IProcessDataOptions
): DataItem[] {
  const { dateKey, groupByKey, countKey } = options;
  // Create an object where the keys are dates and the values are objects containing group counts
  const countsByDate: { [date: string]: { [group: string]: number } } =
    rawData.reduce(
      (
        acc: {
          [date: string]: { [group: string]: number };
        },
        row
      ) => {
        const date = row[dateKey];
        const group = row[groupByKey];
        const count = parseInt(row[countKey]);

        if (!acc[date]) acc[date] = {};
        acc[date][group] = count;

        return acc;
      },
      {}
    );

  // Convert the countsByDate object into the array format that Nivo expects
  return Object.entries(countsByDate).map(([date, counts]) => ({
    date,
    ...counts,
  }));
}

export default processData;
