import React, { FC, useState } from "react";
import {
  GurupressFilterContext,
  IGurupressFilterState,
} from "../../contexts/gurupress/GurupressFilterContext";
import GurupressFilterBar from "../../components/gurupress/GurupressFilterBar";
import {
  LeadCohortTable,
  LeadTurnoverTable,
} from "../../components/gurupress/TurnoverWidgets";
import Header from "../../components/Header";
import { Box } from "@mui/material";

const LeadTurnoverPage: FC = () => {
  const [gurupressFilterState, setGurupressFilterState] =
    useState<IGurupressFilterState>({
      startDate: new Date("2023-01-01"),
      endDate: new Date(),
      leadSource: "all",
      sourceData: {},
    });
  return (
    <>
      <GurupressFilterContext.Provider
        value={{
          filterState: gurupressFilterState,
          setFilterState: setGurupressFilterState,
        }}
      >
        <GurupressFilterBar />
        <Box mx={20} my={5}>
          <Header
            title="Lead Umsatz-Entwicklung"
            subtitle="Detailierte Umsatz & Subscriber Zahlen - Lead-Quelle und Subscriber Start- und End-Datum kann über Formular oben ausgewählt werden"
          />
          <p>Das Startdatum muss mindestens der 01.01.2023 sein</p>
          <LeadTurnoverTable />
        </Box>
        <Box mx={20} my={5}>
          <Header
            title="Kohortentabelle"
            subtitle="⌀ Umsatz pro Monat - Alle Lead-Quellen ( Erste 6 Monate )"
          />
          <LeadCohortTable />
        </Box>
      </GurupressFilterContext.Provider>
    </>
  );
};

export default LeadTurnoverPage;
