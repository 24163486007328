//./react-app/src/pages/AdServerStatistics.tsx
import React from 'react';

const AdServerStatistics: React.FC = () => {
    return (
        <div>
            <h1>Ad Server Statistics test</h1>
            {/* Charts will go here */}
        </div>
    );
};

export default AdServerStatistics;