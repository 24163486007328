//./react-app/src/scenes/vnr/roi-evaluation.tsx
import Header from "../../components/Header";
import React, { FC, useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { FilterContext } from "../../contexts/FilterContext";
import PushSendsPerDay from "../../components/push/PushSendsPerDay";

const PushStatisticsPage: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { filterState } = useContext(FilterContext)!;
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Push Auswertung"
          subtitle="FIT Leads werden mit 3€ bewertet (da dies dem durchschnittlichen Umsatz pro Lead nach 30 Tagen entspricht)"
        />
        <Typography>
          <ul>
            <li>RPM: Revenue per Mille (Umsatz auf 1000 Impressions)</li>
            <li>RPMC: Revenue per Mille Clicks (Umsatz auf 1000 Klicks)</li>
          </ul>
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Gruppierung nach Versand-Tag
        </Typography>
        <Box height="250px" mt="-20px">
          <PushSendsPerDay />
        </Box>
      </Box>
    </Box>
  );
};

export default PushStatisticsPage;
