/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { formatNumber } from "../../helpers/numberUtils";
import { exportToExcel } from "../../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../../helpers/sortingsFuncs";
import LoadingSpinner from "../LoadingSpinner";
import { visuallyHidden } from "@mui/utils";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

// The header mapping for the details table in the popup ( use data from above)
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  ad_form: {
    name: "Ad Form",
    type: "string",
    digits: 0,
    attach: "",
  },
  dynamic: {
    name: "Aktienbezug",
    type: "boolean",
    digits: 0,
    attach: "",
  },
  ad_zone: {
    name: "Ad Zone",
    type: "string",
    digits: 0,
    attach: "",
  },
  opener: {
    name: "Opener",
    type: "number",
    digits: 0,
    attach: "",
  },
  unique_clicks: {
    name: "Clicks (Unique)",
    type: "number",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  turnover: {
    name: "Turnover",
    type: "number",
    digits: 0,
    attach: "€",
  },
  click_rate: {
    name: "Click Rate",
    type: "percentage",
    digits: 3,
    attach: "",
  },
  lead_rate: {
    name: "Lead Rate",
    type: "percentage",
    digits: 3,
    attach: "",
  },
};

type Data = Record<DataKey, string>;
type DataKey = keyof LeadChannelData;
type LeadChannelData = Record<string, any>;

const getData: (
  filterState: IFilterState
) => Promise<Record<string, any>[]> = async (filterState: IFilterState) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const newsletterIds = filterState.newsletters.map((n) => n.id);
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
    customer_id: filterState.customers,
    newsletter_id: newsletterIds[0] !== "all" ? newsletterIds : "",
    ad_material_active: filterState.materialActive,
  };
  const data = (await fetchData<Record<string, any>[]>(
    "/api/adserver/ad-forms",
    options
  )) as Record<string, any>[];
  //convert to numbers where possible
  data.forEach((d) => {
    Object.keys(d).forEach((key) => {
      if (!isNaN(d[key])) {
        d[key] = Number(d[key]);
      }
    });
  });
  return data;
};
export const AdFormStatisticsTable: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<DataKey>("leads");
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(detailsHeaderMapping);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  //for export data, remove product column ( as HTML not allowed in Excel )
  const exportData = data.map((d) => ({ ...d })); // Creating a deep copy
  exportData.forEach((d) => {
    delete d.product;
  });

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() =>
            exportToExcel(exportData, "AdServer-Report-Auswertung")
          }
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={createSortHandler(header)}
                  >
                    {detailsHeaderMapping[header].name || header}
                    {orderBy === header ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<Record<string, any>>(
              data,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                  <TableCell key={key}>
                    {value.type === "number" || value.type === "percentage" ? (
                      row[key] === null ? (
                        ""
                      ) : (
                        formatNumber(
                          Number(row[key]) *
                            (value.type === "percentage" ? 100 : 1),
                          value.digits
                        ) + (value.attach || "")
                      )
                    ) : key === "product" ? (
                      <span dangerouslySetInnerHTML={{ __html: row[key] }} />
                    ) : value.type === "boolean" ? (
                      row[key] == 1 ? (
                        "✔"
                      ) : (
                        "✘️"
                      )
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default AdFormStatisticsTable;
