// ./react-app/src/components/gurupress/GurupressFilterBar.tsx
import React, { FC, useContext, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { SelectChangeEvent } from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  GurupressFilterContext,
  Source,
} from "../../contexts/gurupress/GurupressFilterContext";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 500,
      width: 250,
    },
  },
};

const GurupressFilterBar: FC = () => {
  const { filterState, setFilterState } = useContext(GurupressFilterContext)!;
  const [sources, setSources] = useState<Source>({});

  useEffect(() => {
    // inside useEffect
    fetch("/api/gurupress/lead-sources")
      .then((response) => response.json())
      .then((data) => {
        setSources(data);
        setFilterState((prevState) => ({ ...prevState, sourceData: data }));
      });
  }, []);

  const handleSelectChange =
    (filter: string) => (event: SelectChangeEvent<string>) => {
      setFilterState((prevState) => ({
        ...prevState,
        [filter]: event.target.value as string,
      }));
    };

  const handleDateChange =
    (field: "startDate" | "endDate") => (date: Dayjs | null) => {
      setFilterState((prev) => ({ ...prev, [field]: date?.toDate() }));
    };

  const startDate = filterState.startDate ? dayjs(filterState.startDate) : null;
  const endDate = filterState.endDate ? dayjs(filterState.endDate) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <DatePicker
          label="Member Start-Datum"
          value={startDate}
          onChange={handleDateChange("startDate")}
          format={"DD.MM.YYYY"}
        />
        <DatePicker
          label="Member End-Datum"
          value={endDate}
          onChange={handleDateChange("endDate")}
          format={"DD.MM.YYYY"}
        />
        <FormControl sx={{ width: 200 }}>
          <InputLabel>Lead-Quelle</InputLabel>
          <Select
            value={filterState.leadSource}
            onChange={handleSelectChange("leadSource")}
            input={<OutlinedInput label="Lead-Quelle" />}
            MenuProps={MenuProps}
          >
            {Object.keys(sources).map(
              (key) =>
                sources.hasOwnProperty(key) && (
                  <MenuItem key={key} value={key}>
                    {sources[key]}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </Box>
    </LocalizationProvider>
  );
};

export default GurupressFilterBar;
