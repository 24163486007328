/* ./react-app/src/components/SoldLeadsTable.tsx */
import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { dateToSqlDate } from "../../helpers/dateFuncs";
import fetchData from "../../helpers/fetchData";
import { formatNumber } from "../../helpers/numberUtils";
import { exportToExcel } from "../../helpers/exportFuncs";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import {
  getComparator,
  Order,
  sortedRowInformation,
} from "../../helpers/sortingsFuncs";
import LoadingSpinner from "../LoadingSpinner";
import { visuallyHidden } from "@mui/utils";
import { channelNameMapping } from "../../helpers/nameMappings";

interface HeaderMappingItem {
  name: string;
  type: string;
  digits: number;
  attach: string;
}

// The header mapping for the details table in the popup
const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  source: {
    name: "Kanal",
    type: "string",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  roi30: {
    name: "ROI (30d)",
    type: "number",
    digits: 2,
    attach: "%",
  },
  roi_total: {
    name: "ROI (laufend)",
    type: "number",
    digits: 2,
    attach: "%",
  },
};

type RoiData = {
  source: any;
  leads: number | null;
  roi30: number | null;
  roi_total: number | null;
  [key: string]: any;
};

type Data = Record<DataKey, string>;
type DataKey = keyof LeadChannelData;
type LeadChannelData = Record<string, any>;
/*{
  source: string;
  leads: number;
  revenue: number;
  doi_rate: number;
  roi_rate: number;
};*/

const getData: (filterState: IFilterState) => Promise<RoiData[]> = async (
  filterState: IFilterState
) => {
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: dateToSqlDate(filterState.endDate),
    lead_price: filterState.leadPrice,
  };
  const roiData = (await fetchData<Record<string, any>[]>(
    "/api/vnr/roi-per-source",
    options
  )) as Record<string, any>[];

  //map all data to one array
  return roiData.map((item) => {
    return {
      source: item.source,
      leads: parseInt(item.leads),
      roi30: (item.roi30 as number) * 100 || null,
      roi_total: (item.roi_total as number) * 100 || null,
    };
  });
};
export const LeadChannelsTable: React.FC = () => {
  const { filterState } = useContext(FilterContext)!;
  if (!filterState.startDate || !filterState.endDate) {
    throw new Error("No start date or end date provided");
  }
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<DataKey>("leads");
  const [data, setData] = useState<RoiData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await getData(filterState);
        setData(data);
        setLoading(false);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, [filterState]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const headers = Object.keys(detailsHeaderMapping);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => () => {
    handleRequestSort(property);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <Box bgcolor="#fff">
      <Box>
        <Button
          onClick={() => exportToExcel(data, "ROI-nach-Quelle-Auswertung")}
          sx={{ float: "right" }}
        >
          Download als Excel
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header}
                    direction={orderBy === header ? order : "asc"}
                    onClick={createSortHandler(header)}
                  >
                    {detailsHeaderMapping[header].name || header}
                    {orderBy === header ? (
                      <Box component="span" sx={{ ...visuallyHidden }}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRowInformation<RoiData>(
              data,
              getComparator(order, orderBy)
            ).map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                }}
              >
                {Object.entries(detailsHeaderMapping).map(([key, value]) => (
                  <TableCell key={key}>
                    {value.type === "number"
                      ? row[key] === null
                        ? ""
                        : formatNumber(Number(row[key]), value.digits) +
                          (value.attach || "")
                      : key === "source"
                      ? channelNameMapping[row[key]] || row[key]
                      : row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default LeadChannelsTable;
