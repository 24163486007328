import React, { FC } from "react";
import Header from "../../components/Header";
import { Box, useTheme } from "@mui/material";
import ImportedLeadsTable from "../../components/gurupress/ImportedLeadsTable";

const LeadImportsPage: FC = () => {
  const theme = useTheme();
  return (
    <Box mx={20} my={5}>
      <Header
        title="Importierte Leads nach Herkunft"
        subtitle="Gruppiert nach Jahr/Monat"
      />
      <ImportedLeadsTable />
    </Box>
  );
};

export default LeadImportsPage;
