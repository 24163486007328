import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import React from "react";
import DailyListGrowthBarChart from "../../components/mailchimp/DailyListGrowthBarChart";
import DailyListDevelopmentChart from "../../components/mailchimp/DailyListDevelopmentChart";
import NetListGrowthChart from "../../components/mailchimp/NetListGrowthChart";
import MonthlyListGrowthChart from "../../components/MonthlyListGrowthChart";
import ListSizeLineChart from "../../components/mailchimp/ListSizeLineChart";

const ListGrowth = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Mailchimp E-Mail Liste"
          subtitle="Wachstum & List-Größe"
        />
      </Box>
      <Box>
        <Typography variant="h5" color={colors.blueAccent[400]}>
          <p>Hinweise:</p>
          <ul>
            <li>
              Daten vor 2023 sind mit Vorsicht zu Betrachten, da ein großer Teil
              der Liste gelöscht wurde ( alte Abmelder ) um Mailchimp-Kosten zu
              reduzieren
            </li>
            <li>
              Gruppierte Daten (Wöchentlich, Monatlich) können leicht von den
              richtigen Zahlen abweichen. Denn die Subscriber Daten werden
              täglich gecached, d.h. wenn sich z.B. ein User am 01.01. anmeldet,
              direkt abmeldet und am 03.01. wieder anmeldet zählt er für eine
              monatliche Ansicht Januar zwei mal.
            </li>
          </ul>
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Neue Subscriber ( inkl. jetziger Status )
          </Typography>
          <Box height="250px" mt="-20px">
            <DailyListGrowthBarChart />
          </Box>
        </Box>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Anmelder & Abmelder Entwicklung
          </Typography>
          <Box height="250px" mt="-20px">
            <DailyListDevelopmentChart />
          </Box>
        </Box>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            Netto-Entwicklung
          </Typography>
          <Box height="250px" mt="-20px">
            <NetListGrowthChart />
          </Box>
        </Box>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            List-Entwicklung (internes Tracking)
          </Typography>
          <Box height="250px" mt="-20px">
            <ListSizeLineChart />
          </Box>
        </Box>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            List-Entwicklung auf monatlicher Basis ( Daten von Mailchimp statt
            eigenem Tracking )
          </Typography>
          <Box height="250px">
            <MonthlyListGrowthChart />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ListGrowth;
