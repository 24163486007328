import React, { useState } from "react";
import { Button, Container, TextField, Typography, Box } from "@mui/material";
import { Alert } from "@mui/lab";

export const Login: React.FC = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === "Yes2020!") {
      localStorage.setItem("isLoggedIn", "true");
      window.location.href = "/";
    } else {
      setError(true);
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        component="form"
        onSubmit={submitForm}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2, // space between form elements
          width: "100%",
          maxWidth: 300,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Finanztrends Dashboard
        </Typography>
        <TextField
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Passwort"
          variant="outlined"
          fullWidth
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
        {error && <Alert severity="error">Zugangsdaten Falsch</Alert>}
      </Box>
    </Container>
  );
};
