// themes/chartTheme.ts
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

export const useChartTheme = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return {
    axis: {
      domain: {
        line: {
          stroke: colors.grey[100],
        },
      },
      legend: {
        text: {
          fill: colors.grey[100],
        },
      },
      ticks: {
        line: {
          stroke: colors.grey[100],
          strokeWidth: 1,
        },
        text: {
          fill: colors.grey[100],
        },
      },
    },
    legends: {
      text: {
        fill: colors.grey[100],
      },
    },
  };
};
