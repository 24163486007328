// ./react-app/src/contexts/FilterContext.tsx
import React, { createContext, useState } from "react";

export type Customer = {
  id: string;
  name: string;
  chart_color: string;
};

export type Newsletter = {
  id: string;
  name: string;
};

export interface IFilterState {
  startDate: Date | null;
  endDate: Date | null;
  customers: string[];
  newsletters: Newsletter[];
  display: "daily" | "weekly" | "monthly" | "yearly";
  articleGrouping: string;
  articleType: string;
  viewType: string;
  viewChannel: string;
  viewReferrer: string;
  leadValue: number;
  leadPrice: number;
  samGrouping: string;
  customerData: Customer[];
  lpsLeadsGroupBy: string;
  leadSource: string;
  leadStatus: string;
  productActive: number[];
  materialActive: number[];
  custom?: {
    excludeTitleStart?: boolean;
  };
}

export const FilterContext = createContext<{
  filterState: IFilterState;
  setFilterState: React.Dispatch<React.SetStateAction<IFilterState>>;
} | null>(null);

export const FilterProvider: React.FC<
  React.PropsWithChildren<Record<string, never>>
> = ({ children }) => {
  const [filterState, setFilterState] = useState<IFilterState>({
    startDate: null,
    endDate: null,
    customers: [],
    newsletters: [],
    display: "daily",
    articleGrouping: "author",
    articleType: "all",
    viewType: "ft",
    viewChannel: "all",
    viewReferrer: "all",
    leadValue: 8,
    leadPrice: 12.5,
    samGrouping: "send_type",
    customerData: [],
    lpsLeadsGroupBy: "lead_status",
    leadSource: "all",
    leadStatus: "all",
    productActive: [],
    materialActive: [],
    custom: {},
  });

  return (
    <FilterContext.Provider value={{ filterState, setFilterState }}>
      {children}
    </FilterContext.Provider>
  );
};
