/* ./react-app/src/components/TitleNounEvaluation.tsx */

// The header mapping for the details table in the popup
import React from "react";
import TableWidget, { HeaderMappingItem } from "../../../helpers/tableBuilder";
import { IFilterState } from "../../../contexts/FilterContext";
import { fetchAdServerData } from "../../../helpers/adserverData";

const detailsHeaderMapping: Record<string, HeaderMappingItem> = {
  date: {
    name: "Datum",
    type: "date",
    digits: 0,
    attach: "",
  },
  fit_dois: {
    name: "DOIs",
    type: "number",
    digits: 0,
    attach: "",
  },
  opens: {
    name: "Öffner",
    type: "number",
    digits: 0,
    attach: "",
  },
  mc_clicks: {
    name: "Klicks (Mailchimp Tracking)",
    type: "number",
    digits: 0,
    attach: "",
  },
  clicks: {
    name: "Klicks (AdServer Tracking)",
    type: "number",
    digits: 0,
    attach: "",
  },
  sois: {
    name: "SOIs (AdServer Tracking)",
    type: "number",
    digits: 0,
    attach: "",
  },
  leads: {
    name: "Leads",
    type: "number",
    digits: 0,
    attach: "",
  },
  turnover: {
    name: "Umsatz",
    type: "number",
    digits: 0,
    attach: "€",
  },
  roi_30: {
    name: "ROI30",
    type: "percentage",
    digits: 2,
    attach: "%",
  },
  rpm: {
    name: "RPM (Öffner)",
    type: "number",
    digits: 2,
    attach: "€",
  },
  rpmc: {
    name: "RPMC (Klicks)",
    type: "number",
    digits: 2,
    attach: "€",
  },
};

type ApiResponse = {
  date: string;
  fit_dois: number;
  opens: number;
  mc_clicks: number;
  clicks: number;
  sois: number;
  leads: number;
  sum_anfo_30d: number;
};

type MappedData = {
  date: string;
  fit_dois: number;
  opens: number;
  mc_clicks: number;
  clicks: number;
  sois: number;
  leads: number;
  turnover: number;
  sum_anfo_30d: number;
  roi_30: number;
  rpm: number;
  rpmc: number;
};

const getData: (filterState: IFilterState) => Promise<MappedData[]> = async (
  filterState: IFilterState
) => {
  const data = await fetchAdServerData(
    "/api/adserver/welcome-flows/welcome-campaign",
    filterState
  );
  return data.map((item: ApiResponse) => {
    const turnover = Number(item.leads) * Number(filterState.leadPrice);
    return {
      date: item.date,
      fit_dois: Number(item.fit_dois),
      opens: Number(item.opens),
      mc_clicks: Number(item.mc_clicks),
      clicks: Number(item.clicks),
      sois: Number(item.sois),
      leads: Number(item.leads),
      turnover: Number(turnover),
      sum_anfo_30d: Number(item.sum_anfo_30d),
      roi_30: item.leads ? Number(item.sum_anfo_30d) / turnover : 0,
      rpm: item.opens ? (turnover / Number(item.opens)) * 1000 : 0,
      rpmc: item.clicks ? (turnover / Number(item.clicks)) * 1000 : 0,
    };
  });
};

const computeSummary = (data: MappedData[]): MappedData => {
  const summaryData: MappedData = {
    date: "Insgesamt",
    fit_dois: 0,
    opens: 0,
    mc_clicks: 0,
    clicks: 0,
    sois: 0,
    leads: 0,
    turnover: 0,
    sum_anfo_30d: 0,
    roi_30: 0,
    rpm: 0,
    rpmc: 0,
  };
  data.forEach((item) => {
    summaryData.fit_dois += item.fit_dois;
    summaryData.opens += item.opens;
    summaryData.mc_clicks += item.mc_clicks;
    summaryData.clicks += item.clicks;
    summaryData.sois += item.sois;
    summaryData.leads += item.leads;
    summaryData.turnover += item.turnover;
    summaryData.sum_anfo_30d += item.sum_anfo_30d;
  });
  // Recalculate derived metrics for the summary
  summaryData.roi_30 =
    summaryData.leads > 0 ? summaryData.sum_anfo_30d / summaryData.turnover : 0;
  summaryData.rpm =
    summaryData.opens > 0
      ? (summaryData.turnover / summaryData.opens) * 1000
      : 0;
  summaryData.rpmc =
    summaryData.clicks > 0
      ? (summaryData.turnover / summaryData.clicks) * 1000
      : 0;
  return summaryData;
};

const WelcomeCampaignEvaluation: React.FC = () => {
  return (
    <>
      <TableWidget<MappedData>
        getData={(filterState) => getData(filterState)}
        computeSummary={computeSummary}
        detailsHeaderMapping={detailsHeaderMapping}
        initialSortColumn="date"
        excelDownloadName="welcome-campaign-evaluation"
        sortOrder="desc"
      />
    </>
  );
};

export default WelcomeCampaignEvaluation;
