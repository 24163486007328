import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { FC } from "react";

interface HeaderProps {
  title: string;
  subtitle: string;
  addReactElement?: React.ReactNode;
}

const Header: FC<HeaderProps> = ({ title, subtitle, addReactElement }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        sx={{ mb: "5px" }}
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
      {addReactElement}
    </Box>
  );
};

export default Header;
