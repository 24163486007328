//./react-app/src/scenes/adserver/WelcomeFlowsPage.tsx
import Header from "../../components/Header";
import React, { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DoiZoneEvaluation from "../../components/adserver/welcome-flows/DoiZoneEvaluation";
import WelcomeCampaignEvaluation from "../../components/adserver/welcome-flows/WelcomeCampaignEvaluation";
import PdfZoneEvaluation from "../../components/adserver/welcome-flows/PdfZoneEvaluation";

const WelcomeFlowsStatistics: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Auswertung der Welcome-Flows"
          subtitle="Gruppiert nach Tag"
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          DOI-Zone
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <DoiZoneEvaluation />
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          PDF-Zone
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <PdfZoneEvaluation />
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Willkommens-Kampagne (Mailchimp)
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <WelcomeCampaignEvaluation />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeFlowsStatistics;
