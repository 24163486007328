//./react-app/src/scenes/articles/index.tsx
import Header from "../../components/Header";
import React, { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import PostTimeEvaluation from "../../components/articles/PostTimeEvaluation";
import TitleLengthEvaluation from "../../components/articles/TitleLengthEvaluation";
import TitleNounEvaluation from "../../components/articles/TitleNounEvaluation";
import SentenceEndEvaluation from "../../components/articles/TitleEndEvaluation";
import TitleHasNumberEvaluation from "../../components/articles/TitleHasNumberEvaluation";

const ArticlePatternEvaluation: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Artikel/Titel Auswertung"
          subtitle="Gruppierung & Einteilung über Traffic & Artikel-Metadaten"
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Artikel-Zeit Auswertung
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <PostTimeEvaluation />
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Titel-Länge Auswertung
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <TitleLengthEvaluation />
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Erstes Nomen im Titel Auswertung ( Vorerst Auswahl über Großbuchstabe
          (1) + Kleinbuchstaben(2-12) )
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <TitleNounEvaluation />
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Satzzeichen ( Titel-Ende )
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <SentenceEndEvaluation />
          </Box>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Zahl im Titel?
        </Typography>
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box mt="-20px">
            <TitleHasNumberEvaluation />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticlePatternEvaluation;
