// ./react-app/src/components/vnr/RoiPerMonth.tsx
import React, { useContext, useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useChartTheme } from "../../themes/chartTheme";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { FilterContext, IFilterState } from "../../contexts/FilterContext";
import fetchData from "../../helpers/fetchData";

interface IRoiRatePerMonth {
  roi30: number;
  roi_total: number;
  month: number;
  year: number;
}

interface ISerie {
  id: string;
  data: { x: string; y: number }[];
}

const RoiPerMonth: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartTheme = useChartTheme();
  const { filterState } = useContext(FilterContext)!;
  const [chartData, setChartData] = useState<ISerie[]>([]);
  useEffect(() => {
    const getData = async (filterState: IFilterState) => {
      if (!filterState.startDate) {
        throw new Error("No start date provided");
      }
      const options = {
        start_date: filterState.startDate.toISOString().split("T")[0],
        end_date: filterState.endDate
          ? filterState.endDate.toISOString().split("T")[0]
          : "",
        lead_price: filterState.leadPrice,
      };

      const rawData = (await fetchData<IRoiRatePerMonth[]>(
        "/api/vnr/roi-per-month",
        options,
        true
      )) as IRoiRatePerMonth[];

      return rawData;
    };

    getData(filterState)
      .then((data) => {
        const processedData: ISerie[] = [
          {
            id: "ROI(30)",
            data: data.map((d) => ({
              x: `${d.month}-${d.year}`,
              y: parseFloat(String(d.roi30)) * 100,
            })),
          },
          {
            id: "ROI Laufend",
            data: data.map((d) => ({
              x: `${d.month}-${d.year}`,
              y: parseFloat(String(d.roi_total)) * 100,
            })),
          },
        ];
        setChartData(processedData);
      })
      .catch((error) => {
        console.error("Error fetching and processing data: ", error);
      });
  }, [filterState]);

  if (chartData.length === 0) {
    return <p>Loading...</p>; // Render some loading state or other placeholder
  }

  return (
    <>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: "auto", max: "auto", stacked: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Datum",
          legendPosition: "middle",
          legendOffset: 32,
          /* format: (d) => {
                                                                                   const date = parseDate(d);
                                                                                   return date ? formatDateToDM(date) : "";
                                                                                 },*/
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "DOI Rate",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        pointSize={8}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        theme={chartTheme}
        lineWidth={4}
        enableGridX={false}
        enableGridY={false}
        tooltip={({ point }) => (
          <Box
            sx={{
              padding: "5px 10px",
              color: "#222",
              background: "#e0e0e0",
              boxShadow: "0 0 2px #222",
            }}
          >
            <strong>{point.serieId}</strong>:{" "}
            {parseFloat(point.data.yFormatted as string).toFixed(2)} %
          </Box>
        )}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default RoiPerMonth;
