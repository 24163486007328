//./react-app/src/scenes/articles/index.tsx
import Header from "../../components/Header";
import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { GroupedArticles } from "../../components/articles/ArticleCustomGrouping";

const ArticleStatistics: FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Box justifyContent="space-between" alignItems="center" display="flex">
        <Header
          title="Redaktionelle Auswertung"
          subtitle="Gruppierung & Einteilung über Traffic & Artikel-Metadaten"
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr" gap="20px">
        <Box sx={{ backgroundColor: colors.primary[400] }}>
          <Box height="250px" mt="-20px">
            <GroupedArticles />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ArticleStatistics;
