import fetchData, { IResponseData } from "./fetchData";
import { IFilterState } from "../contexts/FilterContext";
import { dateToSqlDate } from "./dateFuncs";

export async function fetchAdServerData<T extends IResponseData>(
  endpoint: string,
  filterState: IFilterState
) {
  if (!filterState.startDate) {
    throw new Error("No start date provided");
  }
  const options = {
    start_date: dateToSqlDate(filterState.startDate),
    end_date: filterState.endDate ? dateToSqlDate(filterState.endDate) : "",
  };

  return await fetchData<T>(endpoint, options);
}
